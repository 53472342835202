  <div *ngIf="showMenu">
  <app-menu></app-menu>
  </div>
  <router-outlet></router-outlet>
  <!-- <app-employee-list></app-employee-list> -->
  <!-- <app-student></app-student> -->
  
  
  
<!-- <app-bill></app-bill>  -->
<!-- <app-invoice></app-invoice> -->