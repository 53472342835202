<div class="container">
    <div class="panel panel-danger">
      <div class="panel-heading">Advertisement
        <a href="#" routerLink="/admin" class="btn btn-warning" style="float:right">
          <span class="glyphicon glyphicon-arrow-left"></span>
        </a>
        <p></p>
      </div>
      <div class="panel-body">
        <form [formGroup]="fileUploadForm" (ngSubmit)="onFormSubmit()">
          <div class="row">
            <div class="col-sm-12">
              <h4>Select Picture File</h4>
              <h5 class="head-title margin-top-8">
                <span>Select Image File [Max:1000KB]</span>
              </h5>
              <div class="col-sm-6">
                <div class="custom-file">
                  <input type="file" class="form-control" id="uploadedImage" name="uploadedImage"
                    (change)="onFileSelect($event)" #UploadFileInput accept="image/jpeg">
                </div>
              </div>
              <div class="col-sm-6">
                <button class="btn btn-primary" type="submit">Upload and Send</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <p *ngIf="fileUploadedMessage">File sent with {{fileUploadedMessage}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div *ngIf="isLoader">
                <img src="/assets/loader1.gif" alt="Loading..." width="350px"/>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
</div>
