import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Customer } from '../../model/entity/customer';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  customer?: Customer[];
  selectedCustomer?: Customer;
  readonly baseURL = environment.baseURL;
  constructor(private http: HttpClient) { }
  public postCustomer(customer: Customer) {
    return this.http.post(this.baseURL + "/customer", customer).pipe(catchError(this.handleError));
  }
  getCustomertList(): Observable<any> {
    return this.http.get(this.baseURL + '/customer').pipe(retry(2), catchError(this.handleError));
  }
  getCustomer(_id: number) {
    return this.http.get<Customer>(this.baseURL + `/customer/${_id}`).pipe(catchError(this.handleError));
  }
  putCustomer(_id: number, customer: Customer) {
    return this.http.put(this.baseURL + `/customer/${_id}`, customer).pipe(catchError(this.handleError));
  }
  deleteCustomer(_id: number) {
    return this.http.delete(this.baseURL + `/customer/${_id}`).pipe(catchError(this.handleError));
  }
  searchCustomer(_name: string, _phone: string,_email:string): Observable<any> {
    var param = {
      'customerName': _name,
      'phone': _phone,
      'email':_email,

    }
    return this.http.get(this.baseURL + "/customer-search/", { params: param });
  }
  getCustomerTickets(_id: number) {
    return this.http.get<any>(this.baseURL + `/show-customer-tickets/${_id}`);
  }
  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('Client Side Error: ', errorResponse.error.message);
    } else {
      console.error('Server Side Error: ', errorResponse)
    }
    return new Observable();
  }
}