// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //baseURL:'http://localhost:9002/api',
  baseURL:'https://billing.jrtravel.ca/api',
  loaderURL:'/assets/loader1.gif',
  logoURL:'/assets/jr-logo.png',
  logoURLQT:'/assets/qt-logo.png',
  picURL:'./assets/images/members',
  advertiseImagePath:'https://jrtravel.ca/invoice/uploads/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
