import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {Ticket } from '../../model/entity/ticket';
import { environment } from 'src/environments/environment';
import { catchError,retry } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class TicketService {
  ticket?:Ticket[];
  selectedTicket?: Ticket;
  readonly baseURL = environment.baseURL;
  errorMsg: string = '';
  constructor(private http: HttpClient) { }
  public postTicket(ticket: Ticket) {
    return this.http.post(this.baseURL + "/ticket", ticket).pipe(catchError(this.handleError));
  }
  getTicketList():Observable<any>{
    return this.http.get(this.baseURL+'/ticket').pipe(retry(2),catchError(this.handleError));
  }
  getTicket(_id: number) {
    return this.http.get<Ticket>(this.baseURL + `/ticket/${_id}`).pipe(catchError(this.handleError));
  }
  putTicket(_id:number,ticket: Ticket) {
    return this.http.put(this.baseURL + `/ticket/${_id}`, ticket).pipe(catchError(this.handleError));
  }
  deleteTicket(_id: number) {
    return this.http.delete(this.baseURL + `/ticket/${_id}`).pipe(catchError(this.handleError));
  }
  getNextInvoiceNo(){
    return this.http.get(this.baseURL+`/invoiceno/`);
  }
  getTicketsByDate(_startDate: string, _endDate: string,_paymentMode:string): Observable<any> {
    var param = {
      'startDate': _startDate,
      'endDate': _endDate,
      'paymentMode':_paymentMode,
    }
  
    return this.http.get(this.baseURL + "/ticket-report/", { params: param }).pipe(catchError(error => {
        /*if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }*/
        return throwError(this.errorMsg);
      })
    );
  }
  generatePDF(_id:number){
    return this.http.get(this.baseURL + `/pdf/${_id}`).pipe(catchError(this.handleError));
   // return this.http.get(`https://sukhdev:kakuWala@www.jrtravel.ca/invoice/pdf/${_id}`).pipe(catchError(this.handleError));
  }
  postMail(ticket:any){
    return this.http.post(this.baseURL + "/mail", ticket).pipe(catchError(this.handleError));
  }
  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }
  private handleError(errorResponse: HttpErrorResponse) {
    
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('Client Side Error: ', errorResponse.error.message);
    } else {
      console.error('Server Side Error: ', errorResponse)
    }
    return new Observable();
  }
}