import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { retry,catchError, Observable, throwError } from 'rxjs';
@Injectable({
    providedIn: 'root'
  })
export class Advertise{
    readonly baseURL = environment.baseURL;
    constructor(private http: HttpClient) { }
    getCustomerEmails():Observable<any>{
      return this.http.get(this.baseURL+"/customer-emails")
    }
    postMail(mailBody:any){
        return this.http.post(this.baseURL + "/mailw",mailBody);
      }
}