import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {BillComponent} from './form/bill/bill.component';
import {InvoiceComponent} from './print/invoice.component'
import {TicketComponent} from './form/ticket/ticket.component'
import { CustomerComponent } from './form/customer/customer.component';
import { VendorComponent } from './form/vendor/vendor.component';
import { ShowTicketComponent } from './form/ticket/show-ticket.component';
import { CustomerSearchComponent } from './form/customer/customer-search.component';
import { CustomerTicketsComponent } from './form/customer/customer-tickets.component';
import { TicketInvoiceComponent } from './print/ticket-invoice.component';
import { TicketReportComponent } from './form/ticket/ticket-report.component';
import { ParentComponent } from './form/daycare/parent.component';
import { ChildComponent } from './form/daycare/child.component';
import { FeeComponent } from './form/daycare/fee.component';
import { FeeInvoiceComponent } from './print/fee-invoice.component';
import {SearchComponent} from './form/daycare/search.component'
import { DaybookComponent } from './form/daybook/day-book.component';
import { DayCareBillComponent } from './form/daycare-bill/bill.component';
import { DayCareBillInvoiceComponent } from './form/daycare-bill/invoice.component';
import { AdvertiseComponent } from './form/advertise/advertise.component';
const routes: Routes = [
  {path:'bill',component:BillComponent},
  {path:'invoice/:id',component:InvoiceComponent},
  {path:'ticket-invoice/:id',component:TicketInvoiceComponent},
  {path:'generate-invoice/:id',component:TicketInvoiceComponent},
  {path:'create-ticket/:id/:name/:email',component:TicketComponent},
  {path:'ticket-report',component:TicketReportComponent},
  {path:'customer',component:CustomerComponent},
  {path:'customer-search',component:CustomerSearchComponent},
  {path:'customer-tickets/:id/:name',component:CustomerTicketsComponent},
  {path:'vendor',component:VendorComponent},
  {path:'show-ticket/:id',component:ShowTicketComponent},
  {path:'parent',component:ParentComponent},
  {path:'add-child/:id/:name',component:ChildComponent},
  {path:'add-fee/:id/:name',component:FeeComponent},
  {path:'fee-invoice/:id',component:FeeInvoiceComponent},
  {path:'parent-search',component:SearchComponent},
  {path:'day-book',component:DaybookComponent},
  {path:'day-care-bill',component:DayCareBillComponent},
  {path:'day-care-bill/:id',component:DayCareBillInvoiceComponent},
  {path:'advertise',component:AdvertiseComponent},
  {path: '', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
