<style>
    @media screen {
        p.bodyText {
            font-family: times 'Courier New', Courier, monospace;
        }
    }

    @media print {
        p.bodyText {
            font-family: times 'Courier New', Courier, monospace;
        }
    }

    table {
        padding: 5px 5px 5px 5px !important;
    }
</style>
<div *ngIf="loader">
    <img src={{loaderURL}} alt="Loading..." />
</div>
<div *ngIf="!loader">
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <div class="container" style="padding-left: 10px;">
        <div class="box">

            <table border="0" width="100%">
                <tr>
                    <td rowspan="3">
                        <img src={{logoURL}} />
                    </td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td class="text-right">Licence Number : 82483 <br />Consumer Protection BC </td>
                </tr>
            </table>
            <hr />
            <div class="slr-head">
                JR TAX & TRAVEL LTD.<br>
                <p class="slr-head-small">32044 Hillcrest Ave,<br>
                    Abbotsford BC V2T 1S4<br />
                    Office Phone : 604 776 4050<br />
                    Cell Phone : 778 551 3090</p>
            </div>
            <div class="invoice-head">
                Invoice<br>
                <p class="invoice-head-small">
                <table border="1" width="250px">
                    <tr class="row-head-back">
                        <td class="text-center">Invoice #</td>
                        <td class="text-center">Date</td>
                    </tr>
                    <tr>
                        <td class="text-center">{{bill.billNo| number:'5.0-0' | removeComma}}</td>
                        <td class="text-center">{{bill.dated|date: 'MMM dd, yyyy'}}</td>
                    </tr>
                </table>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div>
                <div class="bill-to pre">
                    <h2>Invoice To</h2>
                    {{bill.head|uppercase}}
                </div>
                <div class="qr-code">
                    <qrcode [qrdata]=qrCode [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
                </div>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            <div id="description">
                <table width="100%" align="center">
                    <tr class="row-head-back">
                        <th>DESCRIPTION</th>
                        <th class="text-right">AMOUNT &nbsp;&nbsp;</th>
                    </tr>
                    <tr style="border-bottom: 0px!important;" *ngFor="let bd of bill.BillDetails; let i = index">
                        <td>{{bd.particular|uppercase}}</td>
                        <td class="text-right">{{bd.amount}}</td>
                    </tr>

                </table>
                <div id="description-content" style="text-align:right">
                    <span class="total-left">&nbsp;
                        <i>{{amountInWord}}</i>
                    </span>
                    <span class="total-right">
                        <b>Total: $ {{total|number:'1.2'}}&nbsp;</b>
                    </span>
                </div>
            </div>

            <p><i>&nbsp;Thank you for your business with JR Tax &amp; Travel</i></p>
            <br />
            <p style="font-size:11px"><b>Note:</b> Please check your tickets immediately. JR Tax & Travel will not be
                responsible for errors detected after delivery. Check-in for international flights at least 3 hours
                before
                departure. Tickets issued from Delhi are all 100% non-refundable
                Check-in for domestic flights at least 2 hours before departure. All tickets are restricted and carry a
                cancellation penalty. Other restrictions and liability limitations apply. Please re-confirm your flights
                72
                hours before departure.
            </p>
            <p class="text-center">if you have any query about this invoice, please contact<br>
                Ph. 604 776 4050, 778 551 3090, Email: jrtaxtravel@gmail.com</p>
            <br />
            <i style="font-size:11px">(Subject to Abbotsford BC Jurisdiction only)</i>
        </div>
        <div class="pagebreak"> </div>
        <div>
            <p>&nbsp;</p>
            <b> ITINERARY NOTES:</b><br />
            <ul>
                <li>For date change CAD 300.00 plus any fare difference</li>
                <li>Cancelation CAD 550.00 **24 Hrs prior to departure</li>
                <li>Additional no-show penalty CAD 300.00 within 24 Hours Of departure for any change or cancelation</li>
                <li><b>Due to multiple merchants involved there might be multiple transactions on your credit card,
                    Please note the total will not exceed over quote provided</b></li>
                <li>Name changes are not permitted</li>
                <li>Please check in 4 hours before departure</li>
                <li>Please reconfirm return flights 5 days prior to departure</li>
                <li>No refund or extension of ticket on medical grounds</li>
                <li>Some of the tickets may be non refundable, you can check with your agent</li>
                <li>Refund process takes four to six weeks</li>
                <li>Cash payment refund only by cheque</li>
                <li>No refund after departure or on partially used tickets</li>
                <li>Some of the tickets, if no show then no refund/changes</li>
                <li>Infant should be still under two years of age</li>
                <li>On the returning portion, Baggage allowance may vary with each airline, Please refer to your ticket
                    for baggage allowances</li>
                <li>Maximum size of each piece 62 inches/158cm</li>
                <li>Please ensure that your travel documents such as Passport/ Visas/ PR card etc. are in order as
                    advised</li>
                <li>If visa required pls check if it is still valid</li>
                <li>All orginal OCI card holders need to carry Passport with the stamped OCI</li>
                <li>Most countries require atleast 6month vaildity of passport</li>
                <li>Non indian passport holders require visa for India</li>
                <li>When accompanying children not your own pls carry a no obejection notorized letter in both
                    directions</li>
                <li>We cannot assume responsibility for and will not compensate passengers for matters beyond our
                    control viz bankruptcies overbooking and or changes made by the carrier</li>
            </ul>
        </div>

    </div>
</div>
<p>&nbsp;</p>
<p>&nbsp;</p>