import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Child} from '../../model/entity/child';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChildService {
  child?: Child[];
  selectedchild?: Child;
  readonly baseURL = environment.baseURL;
  constructor(private http: HttpClient) { }
  public postChild(child: Child) {
    return this.http.post(this.baseURL + "/child", child).pipe(catchError(this.handleError));
  }
  getChildList(): Observable<any> {
    return this.http.get(this.baseURL + '/child').pipe(retry(2), catchError(this.handleError));
  }
  getChild(_id: number) :Observable<any>{
    return this.http.get<Child>(this.baseURL + `/child/${_id}`).pipe(catchError(this.handleError));
  }
  putChild(_id: number, child: Child) {
    return this.http.put(this.baseURL + `/child/${_id}`, child).pipe(catchError(this.handleError));
  }
  deleteChild(_id: number) {
    return this.http.delete(this.baseURL + `/child/${_id}`).pipe(catchError(this.handleError));
  }
  searchChild(_name: string, _phone: string,_email:string): Observable<any> {
    var param = {
      'childName': _name,
      'phone': _phone,
      'email':_email,

    }
    return this.http.get(this.baseURL + "/child-search/", { params: param });
  }
  /*getChildren(_id: number) {
    return this.http.get<any>(this.baseURL + `/show-child-children/${_id}`);
  }*/
  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('Client Side Error: ', errorResponse.error.message);
    } else {
      console.error('Server Side Error: ', errorResponse)
    }
    return new Observable();
  }
}