import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShowTicketService } from './show-ticket.service';

@Component({
  selector: 'app-show-ticket',
  templateUrl: './show-ticket.component.html',
  styleUrls: ['./show-ticket.component.css']
}) 

export class ShowTicketComponent implements OnInit {
selectedId:number=-1;
tickets:any;
  constructor(private route:ActivatedRoute,public showTicketService:ShowTicketService) { }

  ngOnInit(): void {
    this.selectedId=this.route.snapshot.params['id'];
    //console.log('id : '+this.selectedId)
    this.showTicketService.getTicket(this.selectedId).subscribe((res => {
      this.tickets = res;
     // console.log(res);
    }))
  }

}
