import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Fee} from '../../model/entity/fee';
import { FeeInvoice} from '../../model/view/fee-invoice';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeeService {
  fee?: Fee[];
  selectedFee?: Fee;
  readonly baseURL = environment.baseURL;
  constructor(private http: HttpClient) { }
  public postFee(fee: Fee) {
    return this.http.post(this.baseURL + "/fee", fee).pipe(catchError(this.handleError));
  }
  getFeeList(): Observable<any> {
    return this.http.get(this.baseURL + '/fee').pipe(retry(2), catchError(this.handleError));
  }
  getFee(_id: number) :Observable<any>{
    return this.http.get<Fee>(this.baseURL + `/fee/${_id}`).pipe(catchError(this.handleError));
  }
  putFee(_id: number, fee: Fee) {
    return this.http.put(this.baseURL + `/fee/${_id}`, fee).pipe(catchError(this.handleError));
  }
  deleteFee(_id: number) {
    return this.http.delete(this.baseURL + `/fee/${_id}`).pipe(catchError(this.handleError));
  }
  searchFee(_name: string, _phone: string,_email:string): Observable<any> {
    var param = {
      'feeName': _name,
      'phone': _phone,
      'email':_email,

    }
    return this.http.get(this.baseURL + "/fee-search/", { params: param });
  }
  getFeeInvoice(_id: number) {
    return this.http.get<FeeInvoice>(this.baseURL + `/fee-invoice/${_id}`).pipe(catchError(this.handleError));
  }
  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('Client Side Error: ', errorResponse.error.message);
    } else {
      console.error('Server Side Error: ', errorResponse)
    }
    return new Observable();
  }

}