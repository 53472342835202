import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { Bill } from '../../model/entity/bill';
import Swal from 'sweetalert2';
import { TicketService } from './ticket.service';
import { VendorService } from '../vendor/vendor.service';
import { CustomerService } from '../customer/customer.service';
import { Ticket } from 'src/app/model/entity/ticket';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent implements OnInit {
  menu:boolean=false;
  vendors: any;
  tickets: any;
  customers: any;
  button?: string;
  title?: string;
  loader: boolean = true;
  id: number = -1;
  invoiceNo?: number;
  isTable: boolean = false;
  total: number = 0;
  selectedCustomerId: number = -1;
  selectedCustomerName: string = '';
  mailTo:string='';
  //dtOptions: DataTables.Settings = {};
  constructor(private fb: FormBuilder, public ticketService: TicketService, public vendorService: VendorService, public customerService: CustomerService, private router: Router, private route: ActivatedRoute) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        minDate: new Date(2021, 0, 1),
        maxDate: new Date(),
        dateInputFormat: 'MMM DD, YYYY',
      });
    this.ticketForm.controls['dated'].setValue(new Date());
  }
  datePickerConfig?: Partial<BsDatepickerConfig>;

  ticketForm = this.fb.group({
    invoiceNo: [],
    customerId: [''],
    vendorId: [''],
    dated: [new Date()],
    TicketDetails: this.fb.array([
      this.addDetailFormGroup()
    ]),
    remarks: [''],
    totalAmount: [],
    paymentMode: [''],
  }
  );
  addDetailFormGroup(): FormGroup {
    return this.fb.group({
      passengerName: [''],
      passengerType: ['Adult'],
      purchasePrice: [],
      salePrice: [],
      commission: [],
      currency: ['CAD'],
    });
  }
  get details() {
    return this.ticketForm.get('TicketDetails') as FormArray;//TicketDetails is the name for mapping of TicketDetails Model for Node JS ORM
  }
  ngOnInit() {
    this.refreshList();
    this.selectedCustomerId = this.route.snapshot.params['id'];
    this.selectedCustomerName = this.route.snapshot.params['name'];
    this.mailTo=this.route.snapshot.params['email'];
    this.ticketForm.patchValue({
      customerId: this.selectedCustomerId,
      dated: ((new Date()).toISOString().substring(0, 10)),
    })

    this.vendorService.getVendorList().subscribe((res => {
      this.vendors = res;
    }))
    this.customerService.getCustomertList().subscribe((res => {
      this.customers = res;
    }))
  }
  addDetailButtonClick(): void {
    (<FormArray>this.ticketForm.get('TicketDetails')).push(this.addDetailFormGroup());
  }
  removeDetailButtonClick(index: number): void {
    (<FormArray>this.ticketForm.get('TicketDetails')).removeAt(index);
    console.log(this.ticketForm.get('TicketDetails'));
  }
  getNextInvoiceNo() {
    this.ticketService.getNextInvoiceNo().subscribe((res) => {
      let json = JSON.parse(JSON.stringify(res))
      if (json.length !== 0)
        this.ticketForm.controls['invoiceNo'].setValue(` ${json[0].invoiceNo + 1}`);
      else
        this.ticketForm.controls['invoiceNo'].setValue(`10001`);
    });
  }
  onSubmit(): void {
    // console.log(this.ticketForm.value);
    this.save();
  }
  save(): void {
    if (this.id == -1) {
      this.ticketService.postTicket(this.ticketForm.value).subscribe((res) => {
        this.tickets = res;
        this.ticketForm.reset();
        //this.refreshList();
        //console.log(this.tickets.id);
        
        if (this.tickets.id > 0){
        this.ticketService.generatePDF(this.tickets.id).subscribe((res) => {
          console.log('pdf generated ',res);
          let id=0;
          let json = JSON.parse(JSON.stringify(res))
          console.log('json ',json);
          if (json.length !== 0)
              id=json.id;
              
          const mailBody = {
            "name": "Jagjit Singh",
            "phone": "7785513090",
            "email": "info@jrtravel.ca",
            "msg": "JR Travel thanks for your kind consideration",
            "url": "<a href='https://www.invoice.jrtravel.ca/generate-invoice/"+this.tickets.id+"' target='_blank'>Click here to see your invoice</a>",
            "mailTo":this.mailTo,
            "attachment":[
              {
                  "filename":"ticket-invoice.pdf",
                  "path":"./"+id+".pdf"
              }
          ]            }
          //,<br/>JR Travel <br/>32044 Hillcrest Ave.<br/>Abbotsford BC V2T 1S4<br/>604-776-4050<br/>778-551-3090<br/>
          this.ticketService.postMail(mailBody).subscribe((res) => {
            console.log("mail posted : ", res);
          });
        })
        this.router.navigate(['/show-ticket/' + this.tickets.id]);
        Swal.fire({
          title: 'Saved!',
          text: 'Record Saved Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      }
      });
    }
    else {
      this.ticketService.putTicket(this.id, this.ticketForm.value).subscribe((res) => {
        this.tickets = res;
        this.ticketForm.reset();
        this.refreshList();
        Swal.fire({
          title: 'Updated!',
          text: 'Record Updated Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      });
    }
  }
  refreshList() {
    /* this.ticketService.getTicketList().subscribe(res => {
       this.tickets = res;
       this.isTable=res.length>0?true:false;
     });*/
    this.ticketForm.controls['paymentMode'].setValue('Cash');
    this.title = "Create Your Ticket";
    this.button = "Save";
    this.ticketForm.controls['vendorId'].setValue(0);
    this.ticketForm.controls['customerId'].setValue(0);
    this.details.patchValue([{ passengerType: 'Adult' }]);
    this.getNextInvoiceNo();
  }
  edit(ticket: Ticket) {
    console.log(ticket);
    this.id = ticket.ticketId;
  }

  delete(_id: number) {
    if (confirm('Are you sure to delete this record?') == true) {
      this.ticketService.deleteTicket(_id).subscribe((res) => {
        this.refreshList();
      });
    }
  }
  resetForm(): void {
    this.getNextInvoiceNo();
    this.ticketForm.reset();
  }
  getSum(): void {
    this.total = 0;
    for (var i = 0; i < this.details.controls.length; i++) {
      this.total += parseInt(this.details.value[i].salePrice);
    }
    this.ticketForm.controls['totalAmount'].setValue(this.total);
  }
  gotoTop() {
    window.scroll({
      top: 120,
      left: 0,
      behavior: 'smooth'
    });
  }
}
