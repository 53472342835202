import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FeeService } from './fee.service'
import { Fee } from 'src/app/model/entity/fee';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html',
  styleUrls: ['./fee.component.css']
})
export class FeeComponent implements OnInit {
  title?: string;
  button?: string;
  childId: number;
  feeId?: number;
  amount?:number;
  dated: Date = new Date();//payment date from
  endDate:Date=new Date();//pament date to
  remarks?: string;
  fees: any;
  isTable: boolean = false;
  datePickerConfig?: Partial<BsDatepickerConfig>;
  constructor(public feeService: FeeService, private route: ActivatedRoute) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        minDate: new Date(2021, 0, 1),
        maxDate: new Date(),
        dateInputFormat: 'MMM-YYYY',
      });
    //this.ticketForm.controls['dated'].setValue(new Date());
    this.button = "save";
    this.childId = -1;
  }
  ngOnInit(): void {

    this.childId = this.route.snapshot.params['id'];
    this.title = "Get Fee for : " + this.route.snapshot.params['name'];
    this.refreshList();
  }
  save(form: NgForm): void {

    if (this.feeId === undefined) {
      this.feeService.postFee(form.value).subscribe((res) => {
        this.resetForm(form);
        this.refreshList();
        Swal.fire({
          title: 'Saved!',
          text: 'Record Saved Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      })
    }
    else {
      this.feeService.putFee(this.feeId, form.value).subscribe((res) => {
        this.resetForm(form);
        this.refreshList();
        Swal.fire({
          title: 'Updated!',
          text: 'Record Updated Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      })
    }
  }
  edit(fee: Fee) {
    //console.log(parent);
    this.feeId = fee.feeId;
    this.childId = fee.childId;
    this.amount=fee.amount;
    this.dated = new Date(fee.dated);
    this.endDate= new Date(fee.endDate);
    this.remarks = fee.remarks;
    this.button = "Update";
    this.title = "Edit fee";
    this.gotoTop();
  }
  delete(id: number): void {
    if (confirm('Are you sure to delete this record?') == true) {
      this.feeService.deleteFee(id).subscribe((res) => {
        this.refreshList();
      });
    }
  }
  refreshList() {
    this.title = "Get fee for : " + this.route.snapshot.params['name'];
    this.childId = this.route.snapshot.params['id'];
    this.feeService.getFee(this.childId).subscribe((res) => {
      this.fees = res;
      this.isTable = res.length > 0 ? true : false;
    })
    this.button = "Save";
    //this.childId = -1;
  }
 
  resetForm(form?: NgForm) {
     if (form)
      form.reset()
  }
  print(id:number):void{
    console.log(id);
  }
  removeSlash() {
    //this.childName= this.childName?.replace(/\//g, " ")
    //str.replace(/\//g, "")
    // console.log(this.parentName);
  }
  gotoTop() {
    window.scroll({
      top: 120,
      left: 10,
      behavior: 'smooth'
    });
  }
}

