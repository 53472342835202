import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Parent} from '../../model/entity/parent';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ParentService {
  parent?: Parent[];
  selectedParent?: Parent;
  readonly baseURL = environment.baseURL;
  constructor(private http: HttpClient) { }
  public postparent(parent: Parent) {
    return this.http.post(this.baseURL + "/parent", parent).pipe(catchError(this.handleError));
  }
  getParentList(): Observable<any> {
    return this.http.get(this.baseURL + '/parent').pipe(retry(2), catchError(this.handleError));
  }
  getParent(_id: number) {
    return this.http.get<Parent>(this.baseURL + `/parent/${_id}`).pipe(catchError(this.handleError));
  }
  putParent(_id: number, parent: Parent) {
    return this.http.put(this.baseURL + `/parent/${_id}`, parent).pipe(catchError(this.handleError));
  }
  deleteParent(_id: number) {
    return this.http.delete(this.baseURL + `/parent/${_id}`).pipe(catchError(this.handleError));
  }
  searchParent(_name: string,_childName:string, _phone: string,_email:string): Observable<any> {
    var param = {
      'parentName': _name,
      'childName': _childName,
      'phone': _phone,
      'email':_email,

    }
    return this.http.get(this.baseURL + "/parent-search/", { params: param });
  }
  getParentChildren(_id: number) {
    return this.http.get<any>(this.baseURL + `/show-parent-children/${_id}`);
  }
  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('Client Side Error: ', errorResponse.error.message);
    } else {
      console.error('Server Side Error: ', errorResponse)
    }
    return new Observable();
  }
}