<div class="container">
    <h3>JR Tax and Travel Ltd. - Ticket Master</h3>
    <form [formGroup]="ticketForm" novalidate (ngSubmit)="onSubmit()">
        <div class="panel panel-success">
            <div class="panel-heading">{{title}} for {{selectedCustomerName}}</div>
            <div class="panel-body">
                <!-- Select Account -->
                <div class="row">
                    <div class="col-sm-2 form-group">
                        <label for="dated" class="control-label">Invoice No.</label>
                        <input type="text" class="form-control" name="invoiceNo" id="invoiceNo"
                            formControlName="invoiceNo" readonly>
                    </div>
                    <div class="col-sm-2 form-group">
                        <label for="dated" class="control-label">Date</label>
                        <input type="text" class="form-control" bsDatepicker [bsConfig]="datePickerConfig" name="dated"
                            id="dated" formControlName="dated" placement="bottom">
                    </div>
                <!-- </div>
                <div class="row"> -->
                    <div class="col-sm-4 form-group">
                        <label for="head" class="control-label">Invoice To</label>&nbsp;&nbsp;&nbsp;&nbsp;[ <a routerLink="/customer" href="#">add customer</a> ]
                            <select id="customerId" name="customerId" formControlName="customerId" class="form-control" required="required">
                                <option disabled value="0">Select</option>
                                <option disabled *ngFor="let customer of customers;let i=index" [value]="customer.customerId">{{customer.customerName|uppercase}}</option>
                            </select>
                    </div>
                    <div class="col-sm-4 form-group">
                        <label for="vendor" class="control-label">Select Vendor</label>&nbsp;&nbsp; [ <a routerLink="/vendor" href="#">add vendor</a> ]
                        <select id="vendorId" name="vendorId" formControlName="vendorId" class="form-control" required="required"> 
                            <option value="0">Select</option>
                            <option *ngFor="let vendor of vendors;let i=index" [value]="vendor.vendorId">{{i+1}}. {{vendor.vendorName|uppercase}}</option>
                        </select>
                    </div>
                </div>
                <p>&nbsp;</p>
                <div class="well">
                    <div formArrayName="TicketDetails" *ngFor="let detail of details.controls; let i=index">
                        <div [formGroupName]="i">
                            <div *ngIf="i>0" class="row">
                                <hr style="border-top: 1px dotted red;" />
                            </div>
                            <div class="row">
                                <div class="col-md-2 form-group">
                                    <label class="control-label" for="passenger">{{i+1}}. Passenger Name</label>
                                    <input class="form-control" id="passengerName" formControlName="passengerName"
                                        placeholder="Enter Passenger Detail" />
                                </div>
                                <div class="col-md-2 form-group">
                                    <label class="control-label" for="passengertype">Passenger Type</label>
                                    <select class="form-control" id="passengerType" name="passsengerType"
                                        formControlName="passengerType">
                                        <option value="Adult">Adult</option>
                                        <option value="Child">Child</option>
                                        <option value="Infant">Infant</option>
                                    </select>
                                </div>
                                <div class="col-md-2 form-group">
                                    <label class="control-label" for="purchaseprice">Purchase Price</label>
                                    <input class="form-control" id="purchasePrice" name="purchasePrice"
                                        formControlName="purchasePrice" placeholder="Enter Buy Amount" />
                                </div>
                                <div class="col-md-2 form-group">
                                    <label class="control-label" for="salePrice">Sale Price</label>
                                    <input class="form-control" id="salePrice" name="salePrice"
                                        placeholder="Enter Sale Price" formControlName="salePrice" (change)="getSum()" />
                                </div>
                                <div class="col-md-1 form-group">
                                    <label class="control-label" for="commission">Commission</label>
                                    <input class="form-control" id="commission" name="commission"
                                        placeholder="Comm" formControlName="commission" />
                                </div>
                                <div class="col-md-2 form-group">
                                    <label class="control-label" for="currency">Currency</label>
                                    <select class="form-control" id="currency" name="currency"
                                        formControlName="currency">
                                        <option>CAD</option>
                                        <option>USD</option>
                                        <option>Rupees</option>
                                    </select>
                                </div>
                                <div *ngIf="i>0">
                                    <div class="col-md-1">
                                        <label class="control-label">&nbsp;</label>
                                        <button (click)="removeDetailButtonClick(i)" type="button"
                                            class="form-control btn btn-danger"><span
                                                class="glyphicon glyphicon-remove"></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="form-group">
                            <div class="col-sm-offset-9  text-right">
                                <button (click)="addDetailButtonClick()" class="form-control btn btn-warning"
                                    type="button">
                                    <span class="glyphicon glyphicon-plus"></span> add more Passenger</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- well ends here-->
                <div class="row">
                    <div class="col-sm-2 form-group">
                        <label class="control-label" for="totalAmount">Total Amount</label>
                        <input type="text" class="form-control" id="totalAmount" name="totalAmount"
                            formControlName="totalAmount" placeholder="Enter Total Amount">
                    </div>
                    <div class="col-sm-2 form-group">
                        <label class="control-label" for="paymentMode">Payment Mode</label>
                        <select class="form-control" id="paymentMode" name="paymentMode" formControlName="paymentMode">
                            <option>Cash</option>
                            <option>Credit Card</option>
                            <option>Bank/E-Transfer</option>
                            <option>Credit</option>
                        </select>
                    </div>
                    <div class="col-sm-8 form-group">
                        <label class="control-label" for="remarks">Remarks (if any)</label>
                        <input type="text" class="form-control" id="remarks" name="remarks" formControlName="remarks"
                            placeholder="Enter Remarks">
                    </div>
                </div>
            </div>
            <div class="panel-footer">
                <div class="form-group">
                    <button type="submit" class="btn btn-info col-sm-2" name="submit" id="submit"
                        value="save">{{button}}</button>
                    <label class="control-label col-sm-1" for="buttons">&nbsp;</label>
                    <button type="reset" class="btn btn-danger col-sm-2">Clear</button>
                    <p>&nbsp;</p>
                </div>
            </div>
        </div>

    </form>
    <!-- {{ticketForm.value|json}} -->
</div>
