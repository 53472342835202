import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators'; 
import { environment } from 'src/environments/environment';
import { Bill } from '../model/entity/bill';
import { BillService } from './../form/bill/bill.service';
import { NumWord } from './../model/numword';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  selectedId?:any;
  bill:any;
  bill$? : Observable<Bill>; 
  amountInWord?:string;
  total?:number=0;
  qrCode:string='';
  billno:number=12536;
  loader:boolean=true;
  loaderURL:string=environment.baseURL;
  logoURL:string=environment.logoURL;
  constructor(private billService:BillService,private router:Router,private route:ActivatedRoute,private numword:NumWord) { }

  ngOnInit(): void {
    this.selectedId=this.route.snapshot.params['_id'];
    this.bill$ = this.route.paramMap.pipe(
           switchMap(params => { 
                this.selectedId = Number(params.get('id'));
                return this.billService.getBill(this.selectedId); })); 
      this.bill$.subscribe( (data) => {this.bill = data;
     // console.log(this.bill.no); 
      console.log(this.bill); 
      this.findsum(this.bill);
      this.qrCode=environment.baseURL
      this.qrCode='https://www.billing.jrtravel.ca/api/verify/'+this.selectedId;
      this.loader=false;
      //console.log(this.qrCode);
    }); 
    
  }
  findsum(data:any)
  {
    //debugger
    let sum:number=0;
    for(let i=0;i<data.BillDetails.length;i++){
      sum+=parseFloat(data.BillDetails[i].amount)
    }
    this.total=sum;
    this.amountInWord=this.numword.getAmountInWords(this.total+'');
  }

}
