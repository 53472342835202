import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators'; 
import { environment } from 'src/environments/environment';
import { FeeInvoice} from '../model/view/fee-invoice';
import { FeeService} from '../form/daycare/fee.service';
import { NumWord } from '../model/numword';

@Component({
  selector: 'app-daycare-fee-invoice',
  templateUrl: './daycare-fee-invoice.component.html',
  styleUrls: ['./daycare-fee-invoice.component.css']
})
export class DayCareFeeInvoiceComponent implements OnInit {
  
  selectedId?:any;
  fees:any;
  amountInWord?:string;
  total?:number=0;
  qrCode:string='';
  invoiceNo:number=12536;
  loader:boolean=true;
  loaderURL:string=environment.loaderURL;
  logoURL:string=environment.logoURLQT;
  constructor(private feeService:FeeService,private router:Router,private route:ActivatedRoute,private numword:NumWord) { }

  ngOnInit(): void {
    this.selectedId=this.route.snapshot.params['id'];
      this.feeService.getFeeInvoice(this.selectedId).subscribe((res=>{
        this.fees=res;
      this.findsum(this.fees);
      this.qrCode=environment.baseURL
      //this.qrCode='https://www.billing.jrtravel.ca/api/verify-invoice/'+this.selectedId;
      this.qrCode='https://www.jrtravel.ca';
      this.loader=false;
    })); 
    
  }

  //pending
  findsum(fee:any)
  {
    //debugger
    let sum:number=0;
    for(let i=0;i<fee.length;i++){
      sum+=parseFloat(fee[i].amount)
    }
    this.total=sum;
    this.amountInWord=this.numword.getAmountInWords(this.total+'');
  }
}