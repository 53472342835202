import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Bill } from '../../model/entity/bill';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillService {
  selectedBill?:Bill;
  bill?:Bill[];
   readonly baseURL=environment.baseURL;;
   //readonly baseURL='https://billing.jrtravel.ca/bill/';
    constructor(private http:HttpClient) { }
    public postBill(bill:Bill){
      return this.http.post(this.baseURL+'/bill',bill);
    }
    getBillList():Observable<any>{
      return this.http.get(this.baseURL+"/bill");
    }
    getBill(_id:string){
      return this.http.get<any>(this.baseURL+`/bill/${_id}`);
    }
    putBill(_id:number,bill:Bill){
      return this.http.put(this.baseURL+`/bill/${_id}`,bill);
    }
    deleteBill(_id:string){
      return this.http.delete(this.baseURL+`/bill/${_id}`);
    }
    //retrieving next bill no
    getLastBillNo(){
      return this.http.get(this.baseURL+`/billno/`);
    }
}
