<div class="container">

    <form #customerForm="ngForm">
        <div class="panel panel-primary">
            <div class="panel-heading">{{title}}<a class="btn btn-warning" style="float:right;margin-top:-7px;" href="#"
                    routerLink="/customer/" title="New Patient"><span class="glyphicon glyphicon-pencil"></span>
                    Add Customer </a></div>
            <div class="panel-body">

                <!-- Select Account -->
                <div class="row">
                    <div class="col-sm-4 form-group">
                        <label for="dated" class="control-label">Customer Name</label>
                        <input type="text" #customerNameControl="ngModel" [(ngModel)]="customerName"
                            class="form-control" name="customerName" id="customerName"
                            placeholder="Enter Customer Name Here">
                    </div>
                    <div class="col-sm-3 form-group">
                        <label for="vendor" class="control-label">Phone No.</label>
                        <input type="text" #phoneControl="ngModel" [(ngModel)]="phone" class="form-control" id="phone"
                            name="phone" placeholder="Enter Phone No. Here" required="required" />
                    </div>
                    <div class="col-sm-3 form-group">
                        <label for="vendor" class="control-label">EMail</label>
                        <input type="text" #phoneControl="ngModel" [(ngModel)]="email" class="form-control" id="email"
                            name="email" placeholder="Enter EMail Here" required="required" />
                    </div>

                    <div class="col-sm-2 form-group">
                        <label for="search" class="control-label">&nbsp;</label>
                        <button type="button" (click)="search()" class="form-control btn btn-info" name="search"
                            id="search" title="Click here to find the customer"><span
                                class="glyphicon glyphicon-search">Search</span></button>
                    </div>
                </div>
                <div *ngIf="showLoadingIndicator">
                    Please wait while fetching record(s)....
                    <img src="/assets/loader1.gif" alt="Loading..." width="250px"/>
                </div>
                <div *ngIf="!showLoadingIndicator">
                    <div class="col-sm-12 form-group">
                        <label>{{recordFound}}</label>
                    </div>
                    <div class="col-sm-12 form-group">
                        <table class="table table-hover display">
                            <tbody>
                                <tr *ngFor="let cust of customers; let i = index">
                                    <td scope="row">{{i+1}}</td>
                                    <td>{{cust.customerName|uppercase}}</td>
                                    <td>{{cust.phone}}</td>
                                    <td>{{cust.dob|date:'MMM dd, yyyy'}}</td>
                                    <td>{{cust.email|lowercase}}</td>
                                    <td><a class="btn btn-primary" href="#"
                                            routerLink="/create-ticket/{{cust.customerId}}/{{cust.customerName}}/{{cust.email}}"><span
                                                class="glyphicon glyphicon-plus"></span>Create Ticket</a></td>
                                    <td><a class="btn btn-info" href="#"
                                            routerLink="/customer-tickets/{{cust.customerId}}/{{cust.customerName}}"><span
                                                class="glyphicon glyphicon-th-list"></span> View Tickets</a></td>
                                    <!-- <td><a class="btn btn-warning" href="#"
                                            routerLink="/payment/{{cust.customerId}}/{{cust.customerName}}/0"><span
                                                class="glyphicon glyphicon-usd"></span>Get Payment</a></td>
                                    <td><a class="btn btn-success" href="#" routerLink="/customer/{{cust.customerId}}/">
                                            <span class="glyphicon glyphicon-pencil"></span> Edit</a>
                                    </td> -->
                                    <!--pending for deleteion of customer-->
                                    <!-- <td>
                                    <a class="btn btn-danger" (click)="delete(cust.customerId)"><span class="glyphicon glyphicon-trash"></span></a>
                                </td> -->

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- <div class="panel-footer">
                <div class="form-group">
                   
                    <button type="button" class="btn btn-info col-sm-2" name="search" id="search">{{button}}</button>
                    <label class="control-label col-sm-1" for="buttons">&nbsp;</label>
                    <button type="reset" class="btn btn-danger col-sm-2">Clear</button>
                    <p>&nbsp;</p>
                </div>
            </div> -->
        </div>

    </form>
    <!-- {{customerForm.value|json}} -->
</div>