import { Component } from '@angular/core';
import { NavigationStart, Router, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'JR Tax and Travel Ltd.';
  
  showMenu:boolean=true;
  constructor(router:Router) {
    router.events.forEach((event) => {
        if(event instanceof NavigationStart) {
          //console.log(event.url)
            this.showMenu = !event.url.startsWith("/generate-invoice");
        }
      });
    }
}
