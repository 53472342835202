import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
//import { DataTableDirective } from 'angular-datatables';
import { TicketService } from './ticket.service';

//import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { parse } from 'path';
@Component({
  selector: 'app-ticket-report',
  templateUrl: './ticket-report.component.html',
  styleUrls: ['./ticket-report.component.css']
})
export class TicketReportComponent implements OnInit {

  showLoadingIndicator: boolean = false;
  readonly loaderURL = environment.loaderURL;
  tickets: any = [];
  startDate: Date = new Date();
  endDate: Date = new Date();
  start: any;
  end: any;
  paymentMode:string=''
  totalAmount: number = -1;
  totalPurchase:number=-1;
  totalCommission: number = -1;
  totalProfit:number=-1;
  
  constructor(private ticketService: TicketService) {

  }
  /* opdReportForm = this.fb.group({
     startDate:  ((new Date()).toISOString().substring(0, 10)),
     endDate:  ((new Date()).toISOString().substring(0, 10)),
   });*/
  ngOnInit(): void {
    const datepipe: DatePipe = new DatePipe('en-US');
    this.start = datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.end = datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.startDate = this.start;
    this.endDate = this.end;
    this.paymentMode='%';
  }
  ngOnDestroy(): void {
    // this.dtTrigger.unsubscribe();
  }
  refreshList(): void {
    this.showLoadingIndicator = true;
    this.ticketService.getTicketsByDate(this.start, this.end,this.paymentMode).subscribe((res) => {
      //res.sort((a:any, b:any) => (a.patientNo> b.patientNo) ? -1 : 1)//sorting in descending
      this.tickets = res;
      this.showLoadingIndicator = false;
      this.totalAmount = 0;
      this.totalPurchase=0;
      this.totalCommission = 0;
      this.totalProfit=0;
      
      for (var i = 0; i < this.tickets.length; i++) {
        this.totalAmount += parseFloat(this.tickets[i].totalAmount);
        this.totalPurchase+=parseFloat(this.tickets[i].purchasePrice);
        this.totalCommission += parseFloat(this.tickets[i].commission === null ? 0 : this.tickets[i].commission)
        this.totalProfit+=parseFloat(this.tickets[i].profit);
      }
    })
  }
  getDailyReport(): void {
    this.showLoadingIndicator = true;
    const datepipe: DatePipe = new DatePipe('en-US');
    this.start = datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.end = datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.startDate = this.start;
    this.endDate = this.end;
    this.refreshList();
  }
  getWeeklyReport(): void {
    this.showLoadingIndicator = true;
    const datepipe: DatePipe = new DatePipe('en-US');
    var enddatetemp = new Date();
    enddatetemp.setDate(enddatetemp.getDate() - 7);
    this.startDate = enddatetemp;
    this.start = datepipe.transform(this.startDate, 'yyyy-MM-dd');
    this.end = datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.startDate = this.start;
    this.endDate = this.end;
    this.refreshList();
  }
  getMonthlyReport(): void {
    this.showLoadingIndicator = true;
    const datepipe: DatePipe = new DatePipe('en-US');
    var enddatetemp = new Date();
    enddatetemp.setDate(enddatetemp.getDate() - 30);
    this.startDate = enddatetemp;
    this.start = datepipe.transform(this.startDate, 'yyyy-MM-dd');
    this.end = datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.startDate = this.start;
    this.endDate = this.end;
    this.refreshList();
  }
  getCompleteReport(): void {
    this.showLoadingIndicator = true;
    const datepipe: DatePipe = new DatePipe('en-US');

    this.start = datepipe.transform('2023-04-01', 'yyyy-MM-dd');
    this.end = datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.startDate = this.start;
    this.endDate = this.end;
    this.refreshList();
  }
  getCustomReport(): void {
    this.showLoadingIndicator = true;
    const datepipe: DatePipe = new DatePipe('en-US');
    this.start = datepipe.transform(this.startDate, 'yyyy-MM-dd');
    this.end = datepipe.transform(this.endDate, 'yyyy-MM-dd');
    this.refreshList();


  }
}
