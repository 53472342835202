<div class="container">
    <form #ticketReportForm="ngForm" novalidate>
        <div class="panel panel-primary">
            <div class="panel-heading">Select Your Report Criteria</div>
            <div class="panel-body">
                <div class="col-sm-3 form-group">
                    <label class="control-label" for="paymentMode">Payment Mode</label>
                    <select class="form-control" id="paymentMode" name="paymentMode" #paymentModeControl="ngModel" [(ngModel)]="paymentMode">
                        <option value="%">Select</option>
                        <option>Cash</option>
                        <option>Credit Card</option>
                        <option>Bank/E-Transfer</option>
                        <option>Credit</option>
                    </select>
                </div>
                <div class="col-sm-3 form-group">
                    <label for="startdate" class="control-label"> Start Date</label>
                    <input type="date" class="form-control" #startDateControl="ngModel" [(ngModel)]="startDate"
                        id="startDate" name="startDate" required="required" />
                </div>
                <div class="col-sm-3 form-group">
                    <label for="enddate" class="control-label">End Date</label>
                    <input type="date" class="form-control" #endDateControl="ngModel" [(ngModel)]="endDate" name="endDate" id="endDate"
                        required="required" />
                </div>
                <div class="col-sm-3 form-group">
                    <label for="submit" class="control-label">&nbsp;</label>
                    <button (click)="getCustomReport()" type="button" class="btn btn-default form-control" name="submit"
                        id="submit">Get Report</button>
                </div>

            </div>
            <div class="panel-footer">
                <div class="form-group btn-toolbar">
                    <button (click)="getDailyReport()" type="button" class="btn btn-default col-sm-2" name="daily"
                        id="daily">Daily
                        Report</button>
                    <button (click)="getWeeklyReport()" type="button" class="btn btn-default col-sm-2" name="weekly"
                        id="weekly">Weekly Report</button>
                    <button (click)="getMonthlyReport()" type="button" class="btn btn-default col-sm-2" name="monthly"
                        id="monthly">Monthly Report</button>
                    <button (click)="getCompleteReport()" type="button" class="btn btn-default col-sm-2" name="complete"
                        id="complete">Complete Report</button>
                </div>
            </div>
        </div>
    </form>
    <!-- {{ticketReportForm.value|json}} -->
    <div *ngIf="showLoadingIndicator">
         <img src="{{loaderURL}}" width="400px"> 
        <div class="loader">Please wait...</div>
    </div>
    <div *ngIf="totalAmount==0" style="color:red">No record found!</div>
</div>
<div class="container-fluid">
    <table *ngIf="tickets?.length && !showLoadingIndicator" class="table table-hover table-bordered display">
        <thead>
            <tr>
                <th>#</th>
                <th>Invoice No.</th>
                <th>Customer Name</th>
                <th>Vendor</th>
                <th>Date</th>
                <th class="text-center">Amount</th>
                <th class="text-center">Purchase</th>
                <th class="text-center" title="Commission">Comm.</th>
                <th class="text-center">Total</th>
                <th class="text-center" title="Payment Mode">Pay Mode</th>
                <th  class="text-center">Action</th>
            </tr>
        </thead>
         <tbody>
            <tr *ngFor="let ticket of tickets; let i = index">
                <td scope="row">{{i+1}}</td>
                <td>{{ticket.invoiceNo}}</td>
                <td>{{ticket.customerName|uppercase}}</td>
                <td>{{ticket.vendorName|uppercase}}</td>
                <td>{{ticket.dated|date:'dd-MM-yyyy'}}</td>
                <td class="text-right">{{ticket.totalAmount}}</td>
                <td class="text-right">{{ticket.purchasePrice}}</td>
                <td class="text-right">{{ticket.commission}}</td>
                <td class="text-right">{{+ticket.totalAmount-ticket.purchasePrice++ticket.commission|number:'1.2'}}</td>
                <td class="text-center">{{ticket.paymentMode}}</td>
                <td class="text-center"><a href='#'  routerLink='/ticket-invoice/{{ticket.id}}' title='Print'><span class='glyphicon glyphicon-print'></span></a></td>
            </tr>
       
            <tr>
                <th colspan="5" class="text-right">Total</th>
                <th class="text-right">{{totalAmount|number:'0.2'}}</th>
                <th class="text-right">{{totalPurchase|number:'0.2'}}</th>
                <th class="text-right">{{totalCommission|number:'0.2'}}</th>
                <th class="text-right">{{totalProfit|number:'0.2'}}</th>
                <th colspan="2"></th>
            </tr>
        </tbody> 
        
            <tfoot>
                <tr>
                    <th colspan="5" class="text-right"></th>
                    <th class="text-right">Amount</th>
                    <th class="text-right">Purchase</th>
                    <th class="text-right">Commission</th>
                    <th class="text-right">Profit</th>
                    <th colspan="2"></th>
                </tr>
            </tfoot>
       
    </table>
</div>-