import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {Vendor } from '../../model/entity/vendor';
import { environment } from 'src/environments/environment';
import { catchError,retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class VendorService {

  vendor?:Vendor[];
  selectedVendor?: Vendor;
  readonly baseURL = environment.baseURL;
  constructor(private http: HttpClient) { }
  public postVendor(vendor: Vendor) {
    return this.http.post(this.baseURL + "/vendor", vendor).pipe(catchError(this.handleError));
  }
  getVendorList():Observable<any>{
    return this.http.get(this.baseURL+'/vendor').pipe(retry(2),catchError(this.handleError));
  }
  getVendor(_id: number) {
    return this.http.get<Vendor>(this.baseURL + `/vendor/${_id}`).pipe(catchError(this.handleError));
  }
  putVendor(_id:number,vendor: Vendor) {
    return this.http.put(this.baseURL + `/vendor/${_id}`, vendor).pipe(catchError(this.handleError));
  }
  deleteVendor(_id: number) {
    return this.http.delete(this.baseURL + `/vendor/${_id}`).pipe(catchError(this.handleError));
  }
  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('Client Side Error: ', errorResponse.error.message);
    } else {
      console.error('Server Side Error: ', errorResponse)
    }
    return new Observable();
  }
}