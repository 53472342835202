import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { CustomerService } from './customer.service';


@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.css']
})
export class CustomerSearchComponent implements OnInit {
  customers: any;
  title: string;
  customerName?: string;
  phone?: string;
  email?:string;
  button?: string;
  showLoadingIndicator: boolean = false;
  recordFound?: string;

  constructor(public customerService: CustomerService) {
    this.title = "Search Customer"
    this.button = "Search"
  }

  ngOnInit(): void {
  }
  search() {
    this.showLoadingIndicator = true;
    this.customerName = this.customerName == null ? '' : this.customerName;
    this.phone = this.phone == null ? '' : this.phone;
    this.email=this.email==null?'':this.email;
    this.customerService.searchCustomer(this.customerName, this.phone,this.email).subscribe((res) => {
      this.customers = res;
      this.recordFound = 'Record Found(s): ' + res.length;
      this.showLoadingIndicator = false;
    },
      error => {
        //debugger;
        this.handleError(error);
        console.log(error.status);
        this.showLoadingIndicator = false;
        this.recordFound = 'Something went wrong';
      }
    )
  }
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      //console.error(`Backend returned code ${error.status}, body was: `, error.error);
      alert(` ${error.status}`);

    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
