<div class="container">
    <div *ngIf="tickets">
        <h3>Invoice Detail for : <b>{{selectedCustomer}}</b></h3>
        <div *ngIf="isTicketDetail">
            <div class="alert alert-success alert-dismissible">
                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>


                <table align="center" width="500px">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Passenger Name</th>
                            <th>Type</th>
                            <th>Commission</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tr *ngFor="let td of ticket.TicketDetails let i = index">
                        <td>{{i+1}}</td>
                        <td>{{td.passengerName}}</td>
                        <td>{{td.passengerType}}</td>
                        <td>{{td.commission}}</td>
                        <td>{{td.salePrice}}</td>
                </table>
            </div>
        </div>
        <table class="table table-hover display">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Invoice No.</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Vendor</th>
                    <th>Action</th>
                    <th>Action</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let ticket of tickets; let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{ticket.invoiceNo}}</td>
                    <td>{{ticket.dated|date:'MMM dd, yyyy'}}</td>
                    <td>{{ticket.totalAmount}}</td>
                    <td>{{ticket.vendorName}}</td>
                    <td><a (click)="showTicketDetail(ticket.id)"><span class="glyphicon glyphicon-th-list"></span></a>
                    <td><a (click)="editTicket(ticket.id)"><span class="glyphicon glyphicon-edit"></span></a></td>
                    <td><a href='#'  routerLink='/ticket-invoice/{{ticket.id}}' title='Print'><span class='glyphicon glyphicon-print'></span></a></td>
                </tr>


            </tbody>
        </table>
    </div>
</div>