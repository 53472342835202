import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { VendorService } from './vendor.service';
import { Vendor } from 'src/app/model/entity/vendor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css']
})
export class VendorComponent implements OnInit {
vendor:any;
vendorId?:number;
title?:string;
button:string="Save";
vendorName?:string;
phone?:string;
email?:string;
dob:Date=new Date();
remarks?:string;
isTable:boolean=false;
datePickerConfig?: Partial<BsDatepickerConfig>;
  constructor(public vendorService:VendorService) { 
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        minDate: new Date(2021, 0, 1),
        maxDate: new Date(),
        dateInputFormat: 'MMM DD, YYYY',
      });
    //this.ticketForm.controls['dated'].setValue(new Date());
    this.button="Save";
    this.title="Create Vendor";
  
  }
ngOnInit(): void {
  this.refreshList();
}
save(form: NgForm): void {
  if (this.vendorId === undefined) {
    this.vendorService.postVendor(form.value).subscribe((res) => {
      this.resetForm(form);
      this.refreshList();
      Swal.fire({
        title: 'Saved!',
        text: 'Record Saved Successfully',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
    })
  }
  else {
    this.vendorService.putVendor(this.vendorId,form.value).subscribe((res) => {
      this.resetForm(form);
      this.refreshList();
      Swal.fire({
        title: 'Updated!',
        text: 'Record Updated Successfully',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
    })
  }
}
edit(vendor: Vendor) {
  //console.log(vendor);
  this.vendorId=vendor.vendorId
  this.vendorName = vendor.vendorName;
  this.dob = new Date(vendor.dob);
  this.email = vendor.email;
  this.phone = vendor.phone;
  this.button = "Update";
  this.title = "Edit Vendor";
  this.gotoTop();
}
delete(id: number): void {
  if (confirm('Are you sure to delete this record?') == true) {
    this.vendorService.deleteVendor(id).subscribe((res) => {
      this.refreshList();

    });
  }
}
refreshList() {
  this.vendorService.getVendorList().subscribe((res) => {
    this.vendor = res;
    this.isTable=res.length>0?true:false;
  })
  this.title = "Create Vendor";
  this.button = "Save";
  this.vendorId = undefined;
}
resetForm(form?: NgForm) {
  console.log(form);
  if (form)
    form.reset()
}
gotoTop() {
  window.scroll({
    top: 120,
    left: 10,
    behavior: 'smooth'
  });
}
}

