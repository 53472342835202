import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BillComponent } from './form/bill/bill.component';
import { InvoiceComponent } from './print/invoice.component'
import { TicketComponent } from './form/ticket/ticket.component'
import { CustomerComponent } from './form/customer/customer.component';
import { VendorComponent } from './form/vendor/vendor.component';
import { ShowTicketComponent } from './form/ticket/show-ticket.component';
import { CustomerSearchComponent } from './form/customer/customer-search.component';
import { CustomerTicketsComponent } from './form/customer/customer-tickets.component';
import { TicketInvoiceComponent } from './print/ticket-invoice.component';
import { TicketReportComponent } from './form/ticket/ticket-report.component';
import { ParentComponent } from './form/daycare/parent.component';
import { ChildComponent } from './form/daycare/child.component';
import { FeeComponent } from './form/daycare/fee.component';
import { FeeInvoiceComponent } from './print/fee-invoice.component';
import { SearchComponent } from './form/daycare/search.component'
import { DaybookComponent } from './form/daybook/day-book.component';
import { DayCareBillComponent } from './form/daycare-bill/bill.component';
import { DayCareBillInvoiceComponent } from './form/daycare-bill/invoice.component';
import { AdvertiseComponent } from './form/advertise/advertise.component';

import { LoginComponent } from './login/login.component'; //12-02-2025
import { AuthGuard } from './_helpers';
const routes: Routes = [
  {
    path: 'bill',
    component: BillComponent,
    canActivate: [AuthGuard]
  },
  { path: 'invoice/:id', component: InvoiceComponent, canActivate: [AuthGuard] },
  { path: 'ticket-invoice/:id', component: TicketInvoiceComponent, canActivate: [AuthGuard] },
  { path: 'generate-invoice/:id', component: TicketInvoiceComponent, canActivate: [AuthGuard] },
  { path: 'create-ticket/:id/:name/:email', component: TicketComponent, canActivate: [AuthGuard] },
  { path: 'ticket-report', component: TicketReportComponent, canActivate: [AuthGuard] },
  { path: 'customer', component: CustomerComponent, canActivate: [AuthGuard] },
  { path: 'customer-search', component: CustomerSearchComponent, canActivate: [AuthGuard] },
  { path: 'customer-tickets/:id/:name', component: CustomerTicketsComponent, canActivate: [AuthGuard] },
  { path: 'vendor', component: VendorComponent, canActivate: [AuthGuard] },
  { path: 'show-ticket/:id', component: ShowTicketComponent, canActivate: [AuthGuard] },
  { path: 'parent', component: ParentComponent, canActivate: [AuthGuard] },
  { path: 'add-child/:id/:name', component: ChildComponent, canActivate: [AuthGuard] },
  { path: 'add-fee/:id/:name', component: FeeComponent, canActivate: [AuthGuard] },
  { path: 'fee-invoice/:id', component: FeeInvoiceComponent, canActivate: [AuthGuard] },
  { path: 'parent-search', component: SearchComponent, canActivate: [AuthGuard] },
  { path: 'day-book', component: DaybookComponent, canActivate: [AuthGuard] },
  { path: 'day-care-bill', component: DayCareBillComponent, canActivate: [AuthGuard] },
  { path: 'day-care-bill/:id', component: DayCareBillInvoiceComponent, canActivate: [AuthGuard] },
  { path: 'advertise', component: AdvertiseComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
