<div class="container">
    <!-- <h3>Parent Master</h3> -->
    <form #parentForm="ngForm" (ngSubmit)=save(parentForm)>
        <div class="panel panel-success">
            <div class="panel-heading">{{title}}</div>
            <div class="panel-body">
                <!-- Select Account -->
                <div class="row">
                    <div class="col-sm-4 form-group">
                        <label for="dated" class="control-label">Select Branch</label>
                        <select #branchControl="ngModel" [(ngModel)]="branch"
                            class="form-control" name="branch" id="branch">
                            <option value="1">1. 1184933 BC Ltd.</option>
                            <option value="2">2. QT Day Care Ltd.</option>
                            <option value="3">3. Quality Time Child Centre</option>
                            </select>
                    </div>
                    <div class="col-sm-4 form-group">
                        <label for="dated" class="control-label">Parent Name</label>
                        <input type="text" #parentNameControl="ngModel" [(ngModel)]="parentName" (blur)="removeSlash()"
                            class="form-control" name="parentName" id="parentName">
                    </div>
                    <div class="col-sm-4 form-group">
                        <label for="head" class="control-label">EMail</label>
                        <input type="text" #emailControl="ngModel" [(ngModel)]="email" class="form-control" id="email"
                            name="email" placeholder="Enter EMail Here" required="required" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 form-group">
                        <label for="vendor" class="control-label">Phone No.</label>
                        <input type="text" #phoneControl="ngModel" [(ngModel)]="phone" class="form-control" id="phone"
                            name="phone" placeholder="Enter Phone No. Here" required="required" />
                    </div>
                
                    <div class="col-sm-4 form-group">
                        <label for="dated" class="control-label">Date of Birth</label>
                        <input type="text" #dobControl="ngModel" [(ngModel)]="dob" class="form-control" bsDatepicker
                            [bsConfig]="datePickerConfig" name="dob" id="dob" placement="bottom">
                    </div>
                    <div class="col-sm-4 form-group">
                        <label class="control-label" for="remarks">Remarks (if any)</label>
                        <input type="text" #remarksControl="ngModel" [(ngModel)]="remarks" class="form-control"
                            id="remarks" name="remarks" placeholder="Enter Remarks">
                    </div>
                </div>
            </div>
            <div class="panel-footer">
                <div class="form-group">
                    <!-- <label class="control-label col-sm-2" for="buttons">&nbsp;</label> -->
                    <button type="submit" class="btn btn-info col-sm-2" name="submit" id="submit"
                        value="save">{{button}}</button>
                    <label class="control-label col-sm-1" for="buttons">&nbsp;</label>
                    <button type="reset" class="btn btn-danger col-sm-2">Clear</button>
                    <p>&nbsp;</p>
                </div>
            </div>
        </div>

    </form>
    <!-- {{parentForm.value|json}} -->
</div>
<div class="container">
    <table class="table table-hover" *ngIf="isTable">
        <thead>
            <tr>
                <th>#</th>
                <th>Branch</th>
                <th>Name</th>
                <th>Phone</th>
                <th>EMail</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let parent of parents; let i = index">
                <td scope="row">{{i+1}}</td>
                <td>{{parent.branch}}</td>
                <td>{{parent.parentName|uppercase}}</td>
                <td>{{parent.phone}}</td>
                <td>{{parent.email|lowercase}}</td>
                <td><a href="#" routerLink="/add-child/{{parent.parentId}}/{{parent.parentName}}"
                    title="Add Child"><span class="glyphicon glyphicon-user"></span></a></td>
                <td><a (click)="edit(parent)" title="Edit"><span class="glyphicon glyphicon-edit"></span></a></td>
                <td><a (click)="delete(parent.parentId)" title="Delete"><span
                            class="glyphicon glyphicon-trash"></span></a></td>
                
            </tr>
        </tbody>
    </table>
    <p *ngIf="!isTable">No parent added yet</p>
</div>