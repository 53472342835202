<div class="container">
    <!-- <h3>Fee</h3> -->
    <form #daybookForm="ngForm" (ngSubmit)=save(daybookForm)>
        <div class="panel panel-success">
            <div class="panel-heading">{{title}}</div>
            <div class="panel-body">
                <!-- Select Account -->
                <div class="row">
                    <div class="col-sm-2 form-group">
                        <label for="dated" class="control-label">Transaction Type</label>
                        <select name="transactionType" #transactionTypeControl="ngModel" [(ngModel)]="transactionType"
                            class="form-control" name="transactionType" id="transactionType">
                            <option value="-1">Select</option>
                            <option value="cr">Received</option>
                            <option value="dr">Paid</option>
                        </select>
                    </div>

                    <div class="col-sm-2 form-group">
                        <label for="dated" class="control-label">From/ To</label>
                        <input type="text" #headControl="ngModel" [(ngModel)]="head" class="form-control" name="head"
                            id="head">
                    </div>

                    <div class="col-sm-2 form-group">
                        <label for="dated" class="control-label">Amount</label>
                        <input type="text" #amountControl="ngModel" [(ngModel)]="amount" class="form-control"
                            name="amount" id="amount">
                    </div>
                    <div class="col-sm-2 form-group">
                        <label for="dated" class="control-label">Date</label>
                        <!-- <input type="text" #datedControl="ngModel" [(ngModel)]="dated" class="form-control" bsDatepicker
                            [bsConfig]="{  adaptivePosition: true, dateInputFormat: 'MM/DD/YYYY'}" name="dated"
                            id="dated" placement="bottom"> -->
                        <input type="text" class="form-control" bsDatepicker [bsConfig]="datePickerConfig" [(ngModel)]="dated" name="dated" id="dated"  placement="bottom">      
                    </div>

                    <div class="col-sm-4 form-group">
                        <label class="control-label" for="narration">Narration</label>
                        <input type="text" #narrationControl="ngModel" [(ngModel)]="narration" class="form-control"
                            id="narration" name="narration" placeholder="Enter Narration">
                    </div>
                </div>
            </div>
            <div class="panel-footer">
                <div class="form-group">
                    <button type="submit" class="btn btn-info col-sm-2" name="submit" id="submit"
                        value="save">{{button}}</button>
                    <label class="control-label col-sm-1" for="buttons">&nbsp;</label>
                    <button type="reset" class="btn btn-danger col-sm-2">Clear</button>

                    <p>&nbsp;</p>
                </div>
            </div>
        </div>
    </form>
    <!-- {{daybookForm.value|json}} -->
</div>
<div *ngIf="isLoader">
    <img src={{loaderURL}} alt="Loading..." />
</div>
<div *ngIf="!isLoader">
 <div class="container"> 
    <table class="table table-hover" *ngIf="isTable">
        <thead>
            <tr>
                <th>#</th>
                <th>Date</th>
                <th>From/To</th>
                <th>Narration</th>
                <th>Amount</th>
                <th>Dr./Cr.</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let db of daybooks; let i = index">
                <td scope="row">{{i+1}}</td>
                <td>{{db.dated|date:'MMM, dd-YYYY'}}</td>
                <td>{{db.head}}</td>
                <td>{{db.narration}}</td>
                <td>{{db.transactionType==='cr'?'+':'-'}}{{db.amount}}</td>
                <!-- <td>{{db.transactionType==='cr'?'+':'-'}}</td> -->
                <td><a (click)="edit(db)" title="Edit"><span class="glyphicon glyphicon-edit"></span></a></td>
                <td><a (click)="delete(db.id)" title="Delete"><span
                            class="glyphicon glyphicon-trash"></span></a></td>

            </tr>
        </tbody>
    </table>
    <p *ngIf="!isTable">No day book entry added yet</p>
</div> 