import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ChildService } from './child.service'
import { Child} from 'src/app/model/entity/child';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-child',
  templateUrl: './child.component.html',
  styleUrls: ['./child.component.css']
})
export class ChildComponent implements OnInit {
  title?: string;
  button?: string;
  parentId:number;
  childId?: number;
  childName?: string;
  dob: Date = new Date();
  doa: Date = new Date();//date of admission
  remarks?:string;
  children: any;
  isTable:boolean=false;
  datePickerConfig?: Partial<BsDatepickerConfig>;
  constructor(public childService: ChildService,private route:ActivatedRoute) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        minDate: new Date(2021, 0, 1),
        maxDate: new Date(),
        dateInputFormat: 'MMM DD, YYYY',
      });
    //this.ticketForm.controls['dated'].setValue(new Date());
    this.button = "save";
    this.parentId=-1;
  }
  ngOnInit(): void {
    
    this.parentId=this.route.snapshot.params['id'];
    this.title="Add Child for : "+this.route.snapshot.params['name'];
    this.refreshList();
  }
  save(form: NgForm): void {
    
    if (this.childId === undefined) {
      this.childService.postChild(form.value).subscribe((res) => {
        this.resetForm(form);
        this.refreshList();
        Swal.fire({
          title: 'Saved!',
          text: 'Record Saved Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      })
    }
    else {
      this.childService.putChild(this.childId,form.value).subscribe((res) => {
        this.resetForm(form);
        this.refreshList();
        Swal.fire({
          title: 'Updated!',
          text: 'Record Updated Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      })
    }
  }
  edit(child: Child) {
    //console.log(parent);
    this.parentId=child.parentId;
    this.childId=child.childId;
    this.childName = child.childName;
    this.dob = new Date(child.dob);
    this.doa = new Date(child.doa);
    this.remarks=child.remarks;
    this.button = "Update";
    this.title = "Edit child";
    this.gotoTop();
  }
  delete(id: number): void {
    if (confirm('Are you sure to delete this record?') == true) {
      this.childService.deleteChild(id).subscribe((res) => {
        this.refreshList();

      });
    }
  }
  refreshList() {
    this.title = "Add Child for : "+this.route.snapshot.params['name'];
    this.parentId =this.route.snapshot.params['id'];
    this.childService.getChild(this.parentId).subscribe((res) => {
      this.children = res;
      this.isTable=res.length>0?true:false;
    })
    this.button = "Save";
    this.childId = undefined;
  }
  fee(id:number):void{
    console.log(id);
  }
  resetForm(form?: NgForm) {
    //console.log(form);
    if (form)
      form.reset()
  }
  removeSlash(){
   this.childName= this.childName?.replace(/\//g, " ")
    //str.replace(/\//g, "")
   // console.log(this.parentName);
  }
  gotoTop() {
    window.scroll({
      top: 120,
      left: 10,
      behavior: 'smooth'
    });
  }
}

