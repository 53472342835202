import { Component } from '@angular/core';
import { NavigationStart, Router, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './_services/token-storage.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'JR Tax and Travel Ltd.';
  
  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username?: string;

  constructor(private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
   // console.log(this.isLoggedIn,', ',this.tokenStorageService.getToken())
    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
     // this.roles = user.roles;

    //  this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
     // this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');

      this.username = user.username;
    }
    else{

    }
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }
    
}
