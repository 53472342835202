<div class="container">
    <!-- <h3>Child Master</h3> -->
    <form #childForm="ngForm" (ngSubmit)=save(childForm)>
        <div class="panel panel-success">
            <div class="panel-heading">{{title}}</div>
            <div class="panel-body">
                <!-- Select Account -->
                <div class="row">
                    <div class="col-sm-3 form-group">
                        <label for="dated" class="control-label">Child Name</label>
                        <input type="text" #childNameControl="ngModel" [(ngModel)]="childName" (blur)="removeSlash()"
                            class="form-control" name="childName" id="childName" placeholder="Enter Child Name here">
                    </div>
                    <div class="col-sm-2 form-group">
                        <label for="dated" class="control-label">Date of Birth</label>
                        <input type="text" #dobControl="ngModel" [(ngModel)]="dob" class="form-control" bsDatepicker
                            [bsConfig]="datePickerConfig" name="dob" id="dob" placement="bottom">
                    </div>
                    <div class="col-sm-2 form-group">
                        <label for="dated" class="control-label">Date of Admission</label>
                        <input type="text" #dobControl="ngModel" [(ngModel)]="doa" class="form-control" bsDatepicker
                            [bsConfig]="datePickerConfig" name="doa" id="doa" placement="bottom">
                    </div>
                    <div class="col-sm-5 form-group">
                        <label class="control-label" for="remarks">Alergy(if any)/ Remarks</label>
                        <input type="text" #remarksControl="ngModel" [(ngModel)]="remarks" class="form-control"
                            id="remarks" name="remarks" placeholder="Enter Alergy/ Remarks here">
                    </div>
                </div>
            </div>
            <div class="panel-footer">
                <div class="form-group">
                    <!-- <label class="control-label col-sm-2" for="buttons">&nbsp;</label> -->
                    <button type="submit" class="btn btn-info col-sm-2" name="submit" id="submit"
                        value="save">{{button}}</button>
                    <label class="control-label col-sm-1" for="buttons">&nbsp;</label>
                    <button type="reset" class="btn btn-danger col-sm-2">Clear</button>
                    <p><input type="hidden" class="form-control" #parentIdControl="ngModel" [(ngModel)]="parentId" id="parentId" name="parentId"></p>
                    <p>&nbsp;</p>
                </div>
            </div>
        </div>

    </form>
    <!-- {{childForm.value|json}} -->
</div>
<div class="container">
    <table class="table table-hover" *ngIf="isTable">
        <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>DOB</th>
                <th>DOA</th>
                <th>Alergy/ Remarks</th>
                <th colspan="3"></th>
                
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let child of children; let i = index">
                <td scope="row">{{i+1}}</td>
                <td>{{child.childName|uppercase}}</td>
                <td>{{child.dob|date:'dd-MM-yyyy'}}</td>
                <td>{{child.doa|date:'dd-MM-yyyy'}}</td>
                <td>{{child.remarks}}</td>
                <td><a href="#" routerLink="/add-fee/{{child.childId}}/{{child.childName}}" title="Get Fee"><span
                    class="glyphicon glyphicon-usd"></span></a></td>
                <td><a (click)="edit(child)" title="Edit"><span class="glyphicon glyphicon-edit"></span></a></td>
                <td><a (click)="delete(child.childId)" title="Delete"><span
                            class="glyphicon glyphicon-trash"></span></a></td>

            </tr>
        </tbody>
    </table>
    <p *ngIf="!isTable">No child added yet</p>
</div>