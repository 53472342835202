import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DayBookService } from './day-book.service'
import { DayBook } from 'src/app/model/entity/daybook';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-daybook',
  templateUrl: './day-book.component.html',
  styleUrls: ['./day-book.component.css']
})
export class DaybookComponent implements OnInit {
  title?: string;
  button?: string;
  transactionType?: string;
  head?: string;
  daybookId?: number;
  amount?: number;
  dated: Date = new Date();//transaction date
  narration?: string;
  daybooks: any;
  isTable: boolean = false;
  isLoader: boolean = true;
  loaderURL: string = environment.baseURL;
  datePickerConfig?: Partial<BsDatepickerConfig>;
  constructor(public daybookService: DayBookService, private route: ActivatedRoute) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        minDate: new Date(2021, 0, 1),
        maxDate: new Date(),
        dateInputFormat: 'MMM DD, YYYY',
      });
      
    this.dated=new Date();
    this.button = "save";
  }
  ngOnInit(): void {
    this.refreshList();
  }
  save(form: NgForm): void {
   // console.log(this.daybookId);
    if (this.daybookId === undefined) {
      this.daybookService.postDayBook(form.value).subscribe((res) => {
        this.resetForm(form);
        this.refreshList();
        Swal.fire({
          title: 'Saved!',
          text: 'Record Saved Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      })
    }
    else {
      this.daybookService.putDayBook(this.daybookId, form.value).subscribe((res) => {
        this.resetForm(form);
        this.refreshList();
        Swal.fire({
          title: 'Updated!',
          text: 'Record Updated Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      })
    }
  }
  edit(daybook: any) {
   // console.log(daybook);
    this.transactionType=daybook.transactionType;
    this.daybookId = daybook.id;
    this.head=daybook.head;
    this.amount = daybook.amount;
    this.dated = new Date(daybook.dated);
    this.narration = daybook.narration;
    this.button = "Update";
    this.title = "Edit Record";
    this.gotoTop();
  }
  delete(id: number): void {
    if (confirm('Are you sure to delete this record?') == true) {
      this.daybookService.deleteDayBook(id).subscribe((res) => {
        this.refreshList();
      });
    }
  }
  refreshList() {
    this.title = "Day Book";
    
    this.button = "Save";
    this.transactionType = "-1";
    this.isLoader = true;
    this.dated = new Date();
    this.daybookService.getDayBookList().subscribe((res) => {
      res.sort((a:any, b:any) => (a.id> b.id) ? -1 : 1)//sorting in descending
      this.daybooks = res;
      this.isLoader = false;
      this.isTable=res.length>0?true:false;
      
    }, error => {
      this.isLoader = false;
      alert(error.statusText);
    })
  }

  resetForm(form?: NgForm) {
    if (form)
      form.reset()
  }
  print(id: number): void {
    console.log(id);
  }
  removeSlash() {
    //this.childName= this.childName?.replace(/\//g, " ")
    //str.replace(/\//g, "")
    // console.log(this.parentName);
  }
  gotoTop() {
    window.scroll({
      top: 120,
      left: 10,
      behavior: 'smooth'
    });
  }
}

