<div class="container">
    <h3>Customer Master</h3>
    <form #customerForm="ngForm" (ngSubmit)=save(customerForm)>
        <div class="panel panel-success">
            <div class="panel-heading">{{title}}</div>
            <div class="panel-body">

                <!-- Select Account -->
                <div class="row">
                    <div class="col-sm-4 form-group">
                        <label for="dated" class="control-label">Customer Name</label>
                        <input type="text" #customerNameControl="ngModel" [(ngModel)]="customerName" (blur)="removeSlash()" class="form-control" name="customerName" id="customerName">
                    </div>
                    <div class="col-sm-4 form-group">
                        <label for="head" class="control-label">EMail</label>
                        <input type="text" #emailControl="ngModel" [(ngModel)]="email" class="form-control" id="email" name="email" 
                            placeholder="Enter EMail Here" required="required" />
                    </div>
                    <div class="col-sm-4 form-group">
                        <label for="vendor" class="control-label">Phone No.</label>
                        <input type="text" #phoneControl="ngModel" [(ngModel)]="phone" class="form-control" id="phone" name="phone"
                            placeholder="Enter Phone No. Here" required="required" />
                    </div>
                    </div>
                <div class="row">
                    <div class="col-sm-4 form-group">
                        <label for="dated" class="control-label">Date of Birth</label>
                        <input type="text" #dobControl="ngModel" [(ngModel)]="dob" class="form-control" bsDatepicker [bsConfig]="datePickerConfig" name="dob"
                            id="dob"  placement="bottom">
                    </div>                    
                    <div class="col-sm-8 form-group">
                        <label class="control-label" for="remarks">Remarks (if any)</label>
                        <input type="text" #remarksControl="ngModel" [(ngModel)]="remarks" class="form-control" id="remarks" name="remarks" 
                            placeholder="Enter Remarks">
                    </div>

                </div>
            </div>
            <div class="panel-footer">
                <div class="form-group">
                    <!-- <label class="control-label col-sm-2" for="buttons">&nbsp;</label> -->
                    <button type="submit" class="btn btn-info col-sm-2" name="submit" id="submit"
                        value="save">{{button}}</button>
                    <label class="control-label col-sm-1" for="buttons">&nbsp;</label>
                    <button type="reset" class="btn btn-danger col-sm-2">Clear</button>
                    <p>&nbsp;</p>
                </div>
            </div>
        </div>

    </form>
    <!-- {{customerForm.value|json}} -->
</div>
<div class="container">
    <table class="table table-hover" *ngIf="isTable">
      <thead>
        <tr>
              <th>#</th>
              <th>Name</th>
              <th>Phone</th>
              <th>EMail</th>
              <th></th>
              <th></th>
          </tr>
        </thead> 
        <tbody>
        <tr *ngFor="let cust of customer; let i = index">
          <td scope="row">{{i+1}}</td>
          <td>{{cust.customerName|uppercase}}</td>
            <td>{{cust.phone}}</td>
            <td>{{cust.email|lowercase}}</td>
            <td><a (click)="edit(cust)" title="Edit"><span class="glyphicon glyphicon-edit"></span></a></td>
            <td><a (click)="delete(cust.customerId)" title="Delete"><span class="glyphicon glyphicon-trash"></span></a></td>
            <td><a  href="#"
                routerLink="/create-ticket/{{cust.customerId}}/{{cust.customerName}}/{{cust.email}}" title="Create ticket"><span
                    class="glyphicon glyphicon-plus"></span></a></td>
          </tr>
      </tbody>
    </table> 
    <p *ngIf="!isTable">No customer added yet</p>    
</div>

