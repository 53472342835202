import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})

export class NumWord
{

    title = 'number-word';
  
       unitdo=["", " One", " Two", " Three", " Four", " Five",
           " Six", " Seven", " Eight", " Nine", " Ten", " Eleven", " Twelve",
           " Thirteen", " Fourteen", " Fifteen",  " Sixteen", " Seventeen",
           " Eighteen", " Nineteen"];
         tens =  ["", "Ten", " Twenty", " Thirty", " Forty", " Fifty",
           " Sixty", " Seventy", " Eighty"," Ninety"];
         digit = ["", " Hundred", " Thousand", " Lakh", " Crore"];
         r?:number;
  
        //Count the number of digits in the input number
    private numberCount(num:number):number
    {
        var cnt:number=0;
        var r:number=0;
        while (num>0)
        {
          r : num % 10;
          cnt++;
          num = Math.floor(num / 10);
          //console.log("num : "+num+", cnt : "+cnt);
        }
          return cnt;
    }

    //Function for Conversion of two digit
    private twonum(numq:number):string
    {
         var  numr, nq;
         var ltr="";
         nq = Math.floor(numq / 10);
         numr = Math.floor(numq % 10);
        // console.log("Two Num nq:"+nq+", numr : "+numr);
         if (numq>19)
         {
           ltr=ltr+this.tens[nq]+this.unitdo[numr];
          }
         else
          {
            ltr = ltr+this.unitdo[numq];
           }
          // alert("2 "+ltr);
         return ltr;
    }

    //Function for Conversion of three digit
    private threenum(numq:number):string
    {
           var numr, nq;
           var ltr = "";
           nq = Math.floor(numq / 100);
           numr = Math.floor(numq % 100);
        // console.log("Three Num nq:"+nq+", numr : "+numr);

           if (numr == 0)
            {
            ltr = ltr + this.unitdo[nq]+this.digit[1];
             }
           else
            {
            ltr = ltr +this.unitdo[nq]+this.digit[1]+" "+this.twonum(numr); //+" and"+
            }
          //  alert("3 : "+ltr);
           return ltr;
    }
  getAmountInWords(st:string):string
  {
     // alert("called "+st);
     var str="";
     if(st.indexOf(".")>0){
      var st1=st.substring(0,st.indexOf("."));
      var st2=st.substring(st.indexOf(".")+1,st.indexOf(".")+3);
     // alert(st2)
      var str=this.getAmountInRupees(st1);
      var paise=parseInt(st2);
      if(paise>0)
      str=str+" and "+this.getAmountInPaise(st2);
     }
     else{
      var str=this.getAmountInRupees(st);
     }
     
       str+=" Only";
       return str;
    // console.log(st1);
     
 }
 getAmountInRupees(st:string):string
 {
  var num=parseInt(st);
  var len:number=0;
  var q:number=0;
  var  r:number=0;
   var ltr = " ";
   var Str = "$";//Rupees/$
   

  if (num < 0)
       console.log("Negative number not for conversion");
   if(num==0)
   {
       return "$ Zero Only";
   }
   //console.log('..'+num);
   while (num>0)
   {
      len = this.numberCount(num);
     //console.log('--'+len);
      //Take the length of the number and do letter conversion
     // console.log("Str : "+Str);
     //  alert(num);
      switch (len)
      {
           case 8:
                   q=Math.floor(num/10000000);
                   r=num%10000000;
                   ltr = this.twonum(q);
                   Str = Str+ltr+this.digit[4];
                   num = r;
                   break;
           case 7:
           case 6:
                   q=Math.floor(num/100000);
                   r=num%100000;
                   ltr = this.twonum(q);
                   Str = Str+ltr+this.digit[3];
                   num = r;
                   break;
           case 5:
           case 4:
                    q=Math.floor(num/1000);
                    r=num%1000;
                    ltr = this.twonum(q);
                    Str= Str+ltr+this.digit[2];
                    num = r;
                    break;
           case 3:
                     if (len == 3)
                         r = num;
                     ltr = this.threenum(r);
                     Str = Str + ltr;
                     num = 0;
                     //console.log(Str+" 3 -"+num);
                     break;
           case 2:
                    ltr = this.twonum(num);
                    Str = Str + ltr;
                    num=0;
                    //console.log("..Str "+Str);
                    break;
           case 1:
                    Str = Str + this.unitdo[num];
                    num=0;
                    break;
           default:
                   num=0;
                   console.log("Exceeding Crore....No conversion");
                   return "";
                   //System.exit(1);
                   
       }
                   //if (num==0)
                   //return "";
                   //System.out.println(Str+" Only");

     }
   return Str;
  }
  getAmountInPaise(st1:string)
         {
           // console.log(".. paise .."+st1);
            var num:number=parseInt(st1);
           if(num<10)
            num=num*10;
              var len, q=0, r=0;
               var ltr = " ";
               var Str = " ";
              // console.log("num "+num);
             if (num <= 0 || num==undefined)
              {
                   console.log("Negative number not for conversion");
                   return;
              }
              while (num>0)
               {
                  len = this.numberCount(num);
                  switch (len)
                  {
                       case 8:
                               q=num/10000000;
                               r=num%10000000;
                               ltr = this.twonum(q);
                               Str = Str+ltr+this.digit[4];
                               num = r;
                               break;
                       case 7:
                       case 6:
                               q=num/100000;
                               r=num%100000;
                               ltr = this.twonum(q);
                               Str = Str+ltr+this.digit[3];
                               num = r;
                               break;
                       case 5:
                       case 4:
                                q=num/1000;
                                r=num%1000;
                                ltr = this.twonum(q);
                                Str= Str+ltr+this.digit[2];
                                num = r;
                                break;
                       case 3:
                                 if (len == 3)
                                     r = num;
                                 ltr = this.threenum(r);
                                 Str = Str + ltr;
                                 num = 0;
                                 break;
                       case 2:
                                ltr = this.twonum(num);
                                Str = Str + ltr;
                                num=0;
                                break;
                       case 1:
                                Str = Str + this.unitdo[num];
                                num=0;
                                break;
                       default:
                               num=0;
                               console.log("Exceeding Crore....No conversion");
                               return ;
                            
                   }
                 }
               return Str+" Cent ";
              }
 }


