<div class="container">
    <!-- <h3>Fee</h3> -->
    <form #feeForm="ngForm" (ngSubmit)=save(feeForm)>
        <div class="panel panel-success">
            <div class="panel-heading">{{title}}</div>
            <div class="panel-body">
                <!-- Select Account -->
                <div class="row">
                    <div class="col-sm-3 form-group">
                        <label for="dated" class="control-label">Amount</label>
                        <input type="text" #amountControl="ngModel" [(ngModel)]="amount"  
                            class="form-control" name="amount" id="amount">
                    </div>
                    <div class="col-sm-2 form-group">
                        <label for="dated" class="control-label">From</label>
                        <input type="text" #datedControl="ngModel" [(ngModel)]="dated" class="form-control" bsDatepicker
                        [bsConfig]="{ minMode:'month', adaptivePosition: true, dateInputFormat: 'MMMM YYYY'}"
                         name="dated" id="dated" placement="bottom">
                    </div>
                    <div class="col-sm-2 form-group">
                        <label for="dated" class="control-label">To</label>
                        <input type="text" #datedControl="ngModel" [(ngModel)]="endDate" class="form-control" bsDatepicker
                        [bsConfig]="{ minMode:'month', adaptivePosition: true, dateInputFormat: 'MMMM YYYY'}"
                         name="endDate" id="endDate" placement="bottom">
                    </div>
                    <div class="col-sm-5 form-group">
                        <label class="control-label" for="remarks">Remarks (if any)</label>
                        <input type="text" #remarksControl="ngModel" [(ngModel)]="remarks" class="form-control"
                            id="remarks" name="remarks" placeholder="Enter Remarks">
                    </div>
                </div>
            </div>
            <div class="panel-footer">
                <div class="form-group">
                    <!-- <label class="control-label col-sm-2" for="buttons">&nbsp;</label> -->
                    <button type="submit" class="btn btn-info col-sm-2" name="submit" id="submit"
                        value="save">{{button}}</button>
                    <label class="control-label col-sm-1" for="buttons">&nbsp;</label>
                    <button type="reset" class="btn btn-danger col-sm-2">Clear</button>
                    <p><input type="hidden" class="form-control" #childIdControl="ngModel" [(ngModel)]="childId" id="childId" name="childId"></p>
                    <p>&nbsp;</p>
                </div>
            </div>
        </div>
    </form>
    <!-- {{feeForm.value|json}} -->
</div>
<div class="container">
    <table class="table table-hover" *ngIf="isTable">
        <thead>
            <tr>
                <th>#</th>
                <th>Amount</th>
                <th>Duration</th>
                <th>Remarks</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let fee of fees; let i = index">
                <td scope="row">{{i+1}}</td>
                <td>{{fee.amount}}</td>
                <td>{{fee.dated|date:'MMM. yyyy'}} - {{fee.endDate|date:'MMM. yyyy'}}</td>
                <td>{{fee.remarks}}</td>
                <td><a href="#" routerLink="/fee-invoice/{{fee.feeId}}" title="Print Fee"><span
                    class="glyphicon glyphicon-print"></span></a></td>
                <td><a (click)="edit(fee)" title="Edit"><span class="glyphicon glyphicon-edit"></span></a></td>
                <td><a (click)="delete(fee.feeId)" title="Delete"><span
                            class="glyphicon glyphicon-trash"></span></a></td>

            </tr>
        </tbody>
    </table>
    <p *ngIf="!isTable">No child added yet</p>
</div> 