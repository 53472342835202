import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { DayBook} from '../../model/entity/daybook';
//import { DayBookInvoice} from '../../model/view/DayBook-invoice';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DayBookService {
 daybook?: DayBook[];
  selectedDayBook?: DayBook;
  readonly baseURL = environment.baseURL;
  constructor(private http: HttpClient) { }
  public postDayBook(DayBook: DayBook) {
    return this.http.post(this.baseURL + "/daybook", DayBook).pipe(catchError(this.handleError));
  }
  getDayBookList(): Observable<any> {
    return this.http.get(this.baseURL + '/daybook').pipe(retry(2), catchError(this.handleError));
  }
  getDayBook(_id: number) :Observable<any>{
    return this.http.get<DayBook>(this.baseURL + `/daybook/${_id}`).pipe(catchError(this.handleError));
  }
  putDayBook(_id: number, DayBook: DayBook) {
    return this.http.put(this.baseURL + `/daybook/${_id}`, DayBook);//.pipe(catchError(this.handleError));
  }
  deleteDayBook(_id: number) {
    return this.http.delete(this.baseURL + `/daybook/${_id}`).pipe(catchError(this.handleError));
  }
  searchDayBook(_name: string, _phone: string,_email:string): Observable<any> {
    var param = {
      'DayBookName': _name,
      'phone': _phone,
      'email':_email,
    }
    return this.http.get(this.baseURL + "/daybook-search/", { params: param });
  }
  /*getDayBookInvoice(_id: number) {
    return this.http.get<DayBookInvoice>(this.baseURL + `/DayBook-invoice/${_id}`).pipe(catchError(this.handleError));
  }*/
  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('Client Side Error: ', errorResponse.error.message);
    } else {
      console.error('Server Side Error: ', errorResponse)
    }
    return new Observable();
  }

}