<style>
    @media screen {
        p.bodyText {
            font-family: times 'Courier New', Courier, monospace;
        }
    }

    @media print {
        p.bodyText {
            font-family: times 'Courier New', Courier, monospace;
        }
    }

    table {
        padding: 5px 5px 5px 5px !important;
    }
</style>
<div *ngIf="loader">
    <img src={{loaderURL}} alt="Loading..." />
</div>
<div *ngIf="!loader">
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <div class="container" style="padding-left: 10px;">
        <div class="box">

            <table border="0" width="100%">
                <tr>
                    <td rowspan="3">
                        <img src={{logoURL}} />
                    </td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td class="text-right"><!--Licence Number : 82483 <br /> Consumer Protection BC --></td>
                </tr>
            </table>
            <hr />


            <div class="slr-head">
                <div *ngIf="fees[0].branch===1">
                    1184933 BC Ltd.<br>
                    <p class="slr-head-small">
                        2316 BEAVER ST <br />
                        ABBOTSFORD BC V2T 3E1<br />
                        
                        Cell Phone : 778 551 3099</p>
                </div>
                <div *ngIf="fees[0].branch===2">
                    QT Day Care Ltd.<br>
                    <p class="slr-head-small"> 2316 Beaver st ,<br>
                        Abbotsford bc v2t 3e1<br />
                        
                        Cell Phone : 778 551 3099</p>
                </div>
                <div *ngIf="fees[0].branch===3">
                    Quality Time Child Centre Inc.<br>
                    <p class="slr-head-small">
                        2865 Cruickshank Street<br />
                        Abbotsford bc v2t 5e7<br />
                        
                        Cell Phone : 778 551 3099</p>
                </div>
            </div>
            <div class="invoice-head">
                Invoice<br>
                <p class="invoice-head-small">
                <table border="1" width="250px">
                    <tr class="row-head-back">
                        <td class="text-center">Invoice #</td>
                        <td class="text-center">Date</td>
                    </tr>
                    <tr>
                        <td class="text-center">{{fees[0].feeId| number:'5.0-0' | removeComma}}</td>
                        <td class="text-center">{{fees[0].dated|date: 'MMM dd, yyyy'}}</td>
                    </tr>
                </table>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
           <p>&nbsp;</p> <p>&nbsp;</p>
            <div>
                <div class="bill-to pre">
                    <h2>Invoice To</h2>
                    {{fees[0].parentName|uppercase}}
                </div>
                <div class="qr-code">
                    <qrcode [qrdata]=qrCode [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
                </div>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            <div id="description">
                <table width="100%" align="center">
                    <tr class="row-head-back">
                        <th>Child Name</th>
                        <th>Fee Month</th>
                        <th class="text-right">Amount&nbsp;&nbsp;</th>
                    </tr>
                    <tr style="border-bottom: 0px!important;" *ngFor="let fee of fees; let i = index">
                        <td>{{fee.childName|uppercase}}</td>
                        <td>{{fee.dated|date: 'MMM yyyy'}}</td>
                        <td class="text-right">{{fee.amount}}</td>
                    </tr>

                </table>
                <div id="description-content" style="text-align:right">
                    <span class="total-left">&nbsp;
                        <i>{{amountInWord}}</i>
                    </span>
                    <span class="total-right">
                        <b>Total: $ {{total|number:'1.2'}}&nbsp;</b>
                    </span>
                </div>
            </div>

            <p><i>&nbsp;Thank you for your business with
                    <span *ngIf="fees[0].branch===1"> 1184933 BC Ltd.</span>
                    <span *ngIf="fees[0].branch===2"> QT Day Care Ltd.</span>
                    <span *ngIf="fees[0].branch===3"> Quality Time Child Centre</span>
                </i></p>
            <br />
            <p style="font-size:11px"><b>Note:</b> Please check your invoice immediately. If you found any dicrepancy, you may contact 
                <span *ngIf="fees[0].branch===1"> 1184933 BC Ltd.</span>
                <span *ngIf="fees[0].branch===2"> QT Day Care Ltd.</span>
                <span *ngIf="fees[0].branch===3"> Quality Time Child Centre</span>
            </p>
            <p class="text-center">if you have any query about this invoice, please contact<br>
                Ph. 778 551 3099, Email: qtdaycareabby@gmail.com</p>
            <br />
            <i style="font-size:11px">(Subject to Abbotsford BC Jurisdiction only)</i>
        </div>
    </div>
</div>
<p>&nbsp;</p>
<p>&nbsp;</p>