import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ShowTicketService } from '../form/ticket/show-ticket.service';
import { NumWord } from '../model/numword';

@Component({
  selector: 'app-ticket-invoice',
  templateUrl: './ticket-invoice.component.html',
  styleUrls: ['./ticket-invoice.component.css']
})
export class TicketInvoiceComponent implements OnInit {
  selectedId?:any;
  ticket:any;
  amountInWord?:string;
  total?:number=0;
  qrCode:string='';
  invoiceNo:number=12536;
  loader:boolean=true;
  loaderURL:string=environment.loaderURL;
  logoURL:string=environment.logoURL;
  constructor(private showTicketService:ShowTicketService,private router:Router,private route:ActivatedRoute,private numword:NumWord) { }

  ngOnInit(): void {
    this.selectedId=this.route.snapshot.params['id'];
      this.showTicketService.getTicket(this.selectedId).subscribe((res=>{
        this.ticket=res;
      this.findsum(this.ticket);
      this.qrCode=environment.baseURL
      this.qrCode='https://www.billing.jrtravel.ca/api/verify-invoice/'+this.selectedId;
      this.loader=false;
    })); 
  }
  findsum(ticket:any)
  {
    //debugger
    let sum:number=0;
    for(let i=0;i<ticket.ticketDetail.length;i++){
      sum+=parseFloat(ticket.ticketDetail[i].salePrice)
    }
    this.total=sum;
    this.amountInWord=this.numword.getAmountInWords(this.total+'');
  }
}
