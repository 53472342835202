import { Component, Inject, OnInit, Optional} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShowTicketService } from './show-ticket.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Button } from 'protractor';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-ticket-commission',
  templateUrl: './add-ticket-commission.component.html',
  styleUrls: ['./add-ticket-commission.component.css']
})
export class AddTicketCommissionComponent implements OnInit {
selectedId:number=0;
selectedName:string='';
selectedInvoiceNo:number=0;
tickets:any;
message:string='';
isButton:boolean=false;
loaderURL:string=environment.baseURL;
  logoURL:string=environment.logoURL;
  constructor(private fb:FormBuilder,private route:ActivatedRoute,private showTicketService:ShowTicketService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddTicketCommissionComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any
    ) { }
    commissionForm = this.fb.group({
      TicketDetails: this.fb.array([
        this.addDetailFormGroup()
      ]),
    }
    );      

    addDetailFormGroup(): FormGroup {
      return this.fb.group({
        id: [''],
        commission: [],
      });
    }
    get details() {
      return this.commissionForm.get('TicketDetails') as FormArray;//TicketDetails is the name for mapping of TicketDetails Model for Node JS ORM
    }
  ngOnInit(): void {
    //console.log('data',this.dialogData);
    this.tickets=this.dialogData;
    this.selectedInvoiceNo=this.tickets.invoiceNo;
    for (let i = this.tickets.TicketDetails.length; i >= 0; i--) {
      // console.log('removing');
      (<FormArray>this.commissionForm.get('TicketDetails')).removeAt(i);
    }
    //pushing  details form control
    for (let i = 0; i < this.tickets.TicketDetails.length; i++) {
      (<FormArray>this.commissionForm.get('TicketDetails')).push(this.fb.group({
        id:this.tickets.TicketDetails[i].id,
      commission: [this.tickets.TicketDetails[i].commission, Validators.required]
      }));
      this.isButton=true;
    }
  }
  closeDialog() { this.dialogRef.close({ event: 'close', data: 'this.fromDialog' }); }
  onSubmit(): void {
    this.updateCommission();
  }
  updateCommission(){
    this.showTicketService.updateTicketCommision(this.commissionForm.value).subscribe((res) => {
      let msg:any;      
      msg=res;
      this.message=msg.message;
      this.isButton=false;
    });
  }
}
