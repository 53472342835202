import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../_services';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  readonly logoURL=environment.logoURL;
  constructor(private authService: AuthenticationService,) { }

  ngOnInit(): void {
   
  }
  logout(){
    this.authService.logout();
  }

}
