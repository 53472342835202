import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { Bill } from '../../model/entity/bill';
import Swal from 'sweetalert2';
import { BillService } from './bill.service';
@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.css']
})
export class BillComponent implements OnInit {
  id: number = -1;
  bdid:number=-1;
  priority:number=0;
  bill: any;
  button?: string;
  title?: string;
  loader: boolean = true;
  total:number=0;
  constructor(private fb: FormBuilder, private billService: BillService) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        minDate: new Date(2021, 0, 1),
        maxDate: new Date(),
        dateInputFormat: 'MMM DD, YYYY',
      });
    this.billForm.controls['dated'].setValue(new Date());
  }
  datePickerConfig?: Partial<BsDatepickerConfig>;

  billForm = this.fb.group({
    billNo: [''],
    dated: [''],
    head: [''],
    BillDetails: this.fb.array([
      this.addDetailFormGroup()
    ]),
    remarks: [''],
  }
  );
 
  //dtOptions: DataTables.Settings = {};
  get details() {
    return this.billForm.get('BillDetails') as FormArray;
  }
  ngOnInit() {
    //this.getNextBillNo();
    this.refreshList();
    this.title = "Create Your Invoice";
    this.button = "Save";
    this.id = -1;
   // this.changePriorityValue('0');
  }
  addDetailButtonClick(): void {
    (<FormArray>this.billForm.get('BillDetails')).push(this.addDetailFormGroup());
     //console.log(this.billForm.get('details'));
  }

  removeDetailButtonClick(index: number): void {
    (<FormArray>this.billForm.get('BillDetails')).removeAt(index);
    this.priority--;
    // console.log(this.billForm.get('details'));
  }
  changePriorityValue(newPriority: string): void {
    const BillDetails = this.billForm.get('BillDetails') as FormArray;
    const firstDetail = BillDetails.at(0) as FormGroup;
    // Use setValue or patchValue to change the value of "Priority"
    firstDetail.get('priority')?.setValue(newPriority);
  }
  addDetailFormGroup(): FormGroup {
    return this.fb.group({
      particular: ['', Validators.required],
      amount: ['', Validators.required],
      priority:[this.priority++],
    });
  }
  getNextBillNo() {
    this.billService.getLastBillNo().subscribe((res) => {
      let json = JSON.parse(JSON.stringify(res))
      if (json.length === 0)
        this.billForm.controls['billNo'].setValue("1");
      else
        this.billForm.controls['billNo'].setValue(` ${json[0].billno + 1}`);
    });

  }

  onSubmit(): void {
    // console.log(this.billForm.value);
    this.saveBill();
  }

  saveBill(): void {
    //console.log(this.billForm.controls['_id'].value);
    if (this.id == -1) {
      this.billService.postBill(this.billForm.value).subscribe((res) => {
       // this.billForm.reset();
        this.refreshList();
        Swal.fire({
          title: 'Saved!',
          text: 'Record Saved Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      },error=>{
        this.loader=false;
        alert(error.statusText);
      })
    }
    else {
      this.billService.putBill(this.id,this.billForm.value).subscribe((res) => {
       // this.billForm.reset();
        this.refreshList();
        Swal.fire({
          title: 'Updated!',
          text: 'Record Updated Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        });

      },error=>{
        this.loader=false;
        alert(error.statusText);
      })
    }
  }
  refreshList() {
    this.id = -1;
    this.billService.getBillList().subscribe((res) => {
      this.billService.bill = res as Bill[];
      this.bill = res;
      //console.log(res);
      this.title = "Create Your Invoice";
      this.button = "Save";
      this.resetForm();
     // this.getNextBillNo();
      
      this.loader = false;
      //this.priority=0;
    },error=>{
      this.loader=false;
      alert(error.statusText);
    })
  }
  edit(bill: Bill) {
    this.id = bill.id;
    this.billForm.controls['billNo'].setValue(bill.billNo);
    this.billForm.controls['head'].setValue(bill.head);
    this.billForm.controls['dated'].setValue(new Date(bill.dated));
    //removing the exisiting detail form ccontrol
    for (let i = bill.BillDetails.length; i >= 0; i--) {
      // console.log('removing');
      (<FormArray>this.billForm.get('BillDetails')).removeAt(i);
    }
    //pushing  details form control
    for (let i = 0; i < bill.BillDetails.length; i++) {
      (<FormArray>this.billForm.get('BillDetails')).push(this.fb.group({
        id:bill.BillDetails[i].id,
        particular: [bill.BillDetails[i].particular, Validators.required],
        amount: [bill.BillDetails[i].amount, Validators.required],
        priority:[i],
      }));
      this.priority=i+1;
    }

    this.billForm.controls['remarks'].setValue(bill.remarks);
    this.button = "Update";
    this.title = "Update Your Bill";
    this.gotoTop();
  }

  copy(bill: Bill) {
    //this.billForm.controls['_id'].setValue(bill._id);
    this.id = -1;
   
    this.billForm.controls['billNo'].setValue(this.getNextBillNo());
    this.billForm.controls['head'].setValue(bill.head);
    this.billForm.controls['dated'].setValue(new Date());
    //removing the exisiting detail form ccontrol
    //console.log(bill.details);
    for (let i = bill.BillDetails.length; i >= 0; i--) {
      // console.log('removing');
      (<FormArray>this.billForm.get('BillDetails')).removeAt(i);
    }
    //pushing  details form control
    for (let i = 0; i < bill.BillDetails.length; i++) {

      (<FormArray>this.billForm.get('BillDetails')).push(this.fb.group({
        particular: [bill.BillDetails[i].particular, Validators.required],
        amount: [bill.BillDetails[i].amount, Validators.required],
        priority:[i],
        
      }));
      this.priority=i+1;
    }
    this.billForm.controls['remarks'].setValue(bill.remarks);
    this.button = "Copy";
    this.title = "Copy Your Bill";
    this.gotoTop();
  }
  delete(_id: string) {
    if (confirm('Are you sure to delete this record?') == true) {
      this.billService.deleteBill(_id).subscribe((res) => {
        this.refreshList();
      });
    }
  }
  resetForm(): void {
    this.getNextBillNo();
    this.billForm.reset();
    this.billForm.controls['dated'].setValue(new Date());
    this.changePriorityValue('0');
    this.removeFormSubDetail();
  }
  removeFormSubDetail() {
    const billDetails = this.billForm.get('BillDetails') as FormArray;
    //console.log('length ',dayCareBillDetails.length,' --- ',dayCareBillDetails);
    const firstDetail = billDetails.at(0) as FormGroup;
    //removing the exisiting detail form ccontrol
    for (let i = billDetails.length; i > 0; i--) {
      // console.log('removing');
      (<FormArray>this.billForm.get('BillDetails')).removeAt(i);
    }
  }
  gotoTop() {
    window.scroll({
      top: 120,
      left: 0,
      behavior: 'smooth'
    });
  }
  getSum(data:any)
  {
    //debugger
    let sum:number=0;
    for(let i=0;i<data.length;i++){
      sum+=parseFloat(data[i].amount)
    }
    this.total=sum;
    return this.total;
  }
}
