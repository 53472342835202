import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CustomerService } from './customer.service';
import { Customer } from 'src/app/model/entity/customer';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  title?: string;
  button?: string;
  customerId?: number;
  customerName?: string;
  dob: Date = new Date();
  phone?: string;
  email?: string;
  remarks?: string;
  customer: any;
  isTable:boolean=false;
  datePickerConfig?: Partial<BsDatepickerConfig>;
  constructor(public customerService: CustomerService) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        minDate: new Date(2021, 0, 1),
        maxDate: new Date(),
        dateInputFormat: 'MMM DD, YYYY',
      });
    //this.ticketForm.controls['dated'].setValue(new Date());
    this.button = "save";

  }
  ngOnInit(): void {
    this.refreshList();
  }
  save(form: NgForm): void {
    if (this.customerId === undefined) {
      this.customerService.postCustomer(form.value).subscribe((res) => {
        this.resetForm(form);
        this.refreshList();
        Swal.fire({
          title: 'Saved!',
          text: 'Record Saved Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      })
    }
    else {
      this.customerService.putCustomer(this.customerId,form.value).subscribe((res) => {
        this.resetForm(form);
        this.refreshList();
        Swal.fire({
          title: 'Updated!',
          text: 'Record Updated Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      })
    }
  }
  edit(customer: Customer) {
    //console.log(customer);
    this.customerId=customer.customerId
    this.customerName = customer.customerName;
    this.dob = new Date(customer.dob);
    this.email = customer.email;
    this.phone = customer.phone;
    this.button = "Update";
    this.title = "Edit Customer";
    this.gotoTop();
  }
  delete(id: number): void {
    if (confirm('Are you sure to delete this record?') == true) {
      this.customerService.deleteCustomer(id).subscribe((res) => {
        this.refreshList();

      });
    }
  }
  refreshList() {
    this.customerService.getCustomertList().subscribe((res) => {
      this.customer = res;
      this.isTable=res.length>0?true:false;
    })
    this.title = "Create Customer";
    this.button = "Save";
    this.customerId = undefined;
  }
  resetForm(form?: NgForm) {
    //console.log(form);
    if (form)
      form.reset()
  }
  removeSlash(){
   this.customerName= this.customerName?.replace(/\//g, " ")
    //str.replace(/\//g, "")
   // console.log(this.customerName);
  }
  gotoTop() {
    window.scroll({
      top: 120,
      left: 10,
      behavior: 'smooth'
    });
  }
}

