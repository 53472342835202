import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ParentService } from './parent.service'
import { Parent} from 'src/app/model/entity/parent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.css']
})
export class ParentComponent implements OnInit {
  title?: string;
  button?: string;
  branch?:number;
  parentId?: number;
  parentName?: string;
  dob: Date = new Date();
  phone?: string;
  email?: string;
  remarks?: string;
  parents: any;
  isTable:boolean=false;
  datePickerConfig?: Partial<BsDatepickerConfig>;
  constructor(public parentService: ParentService) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        maxDate: new Date(),
        dateInputFormat: 'MMM DD, YYYY',
      });
    //this.ticketForm.controls['dated'].setValue(new Date());
    this.button = "save";
    this.branch=3;

  }
  ngOnInit(): void {
    this.refreshList();
  }
  save(form: NgForm): void {
    if (this.parentId === undefined) {
      this.parentService.postparent(form.value).subscribe((res) => {
        this.resetForm(form);
        this.refreshList();
        Swal.fire({
          title: 'Saved!',
          text: 'Record Saved Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      })
    }
    else {
      this.parentService.putParent(this.parentId,form.value).subscribe((res) => {
        this.resetForm(form);
        this.refreshList();
        Swal.fire({
          title: 'Updated!',
          text: 'Record Updated Successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      })
    }
  }
  edit(parent: Parent) {
    //console.log(parent);
    this.parentId=parent.parentId;
    this.branch=parent.branch;
    this.parentName = parent.parentName;
    this.dob = new Date(parent.dob);
    this.email = parent.email;
    this.phone = parent.phone;
    this.button = "Update";
    this.title = "Edit parent";
    this.gotoTop();
  }
  delete(id: number): void {
    if (confirm('Are you sure to delete this record?') == true) {
      this.parentService.deleteParent(id).subscribe((res) => {
        this.refreshList();

      });
    }
  }
  refreshList() {
    this.parentService.getParentList().subscribe((res) => {
      this.parents = res;
      this.isTable=res.length>0?true:false;
    })
    this.title = "Create Parent";
    this.button = "Save";
    this.parentId = undefined;
  }
  resetForm(form?: NgForm) {
    //console.log(form);
    if (form)
      form.reset()
  }
  removeSlash(){
   this.parentName= this.parentName?.replace(/\//g, " ")
    //str.replace(/\//g, "")
   // console.log(this.parentName);
  }
  gotoTop() {
    window.scroll({
      top: 120,
      left: 10,
      behavior: 'smooth'
    });
  }
}

