<div class="container"><h3>JR Tax and Travel Ltd. - Invoice Master</h3>
  <form  [formGroup]="billForm" novalidate (ngSubmit)="onSubmit()">
  <div class="panel panel-success">
      <div class="panel-heading">{{title}}</div>
      <div class="panel-body">
            <!-- Select Account -->
             <div class="row"> 
              <div class="col-sm-6 form-group">
                  <label for="billNo" class="control-label">Invoice No.</label>
                    <input type="text" class="form-control" id="billNo" formControlName="billNo"  name="billNo" placeholder="Enter Bill No." required="required" readonly>
                  </div>    
              <div class="col-sm-6 form-group">
              <label for="dated" class="control-label">Date</label>
                  <input type="text" class="form-control" bsDatepicker [bsConfig]="datePickerConfig"  name="dated" id="dated"  formControlName="dated"  placement="bottom">  
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 form-group">
                  <label  for="head" class="control-label">Head</label>
                    <textarea rows="3"  class="form-control"  id="head" name="head" formControlName="head" placeholder="Enter Head Name" required="required"></textarea>
                  </div>
            </div>
            <!-- <div formGroupName="well"> -->
            <div class="well">
              <div class="form-group">
              <div formArrayName="BillDetails" *ngFor="let detail of details.controls; let i=index">
              <div [formGroupName]="i">
                <div *ngIf="i>0" class="row">
                  <hr style="border-top: 1px dotted red;" />
              </div>
              <div class="row">
                <div class="col-sm-8 form-group">
                  <label class="control-label" for="particular">{{i+1}}. Invoice Particular</label>
                  <input class="form-control" id="particular" formControlName="particular" placeholder="Enter Invoice Particular Detail"/>
                </div>
                  <div class="col-sm-3">
                    <label class="control-label" for="amount">Amount</label>
                    <input class="form-control" id="amount" formControlName="amount" placeholder="Enter Amount"/>
                  </div>
                 <div *ngIf="i>0">
                  <div class="col-sm-1">
                    <label class="control-label">&nbsp;</label>
                    <button (click)="removeDetailButtonClick(i)" type="button" class="form-control btn btn-danger"><span class="glyphicon glyphicon-remove"></span></button>  
                  </div>
                </div>
              </div>
             </div>
            </div> 
            <br/>
            <div class="row">
              <div class="form-group">
                  <div class="col-sm-offset-9  text-right">
                      <button (click)="addDetailButtonClick()" class="form-control btn btn-warning"
                          type="button">
                          <span class="glyphicon glyphicon-plus"></span> add more detail</button>
                  </div>
              </div>
          </div>
          </div>
        </div>
         
          <div class="row">
            <div class="col-sm-12 form-group">
              <label class="control-label" for="remarks">Remarks</label>
                <input type="text" class="form-control" id="remarks" name="remarks" formControlName="remarks" placeholder="Enter Remarks" >
              </div>
            
            </div>
            </div>
          <div class="panel-footer">
              <div class="form-group">
                  <!-- <label class="control-label col-sm-2" for="buttons">&nbsp;</label> -->
              <button type="submit"  class="btn btn-info col-sm-2" name="submit" id="submit" value="save">{{button}}</button>
              <label class="control-label col-sm-1" for="buttons">&nbsp;</label>
             <button type="reset"  class="btn btn-danger col-sm-2">Clear</button>
             <p>&nbsp;</p>
             </div>
          </div>
        </div>
       
      </form>
     <!-- {{billForm.value|json}} -->
   </div>
   <div *ngIf="loader">
    <img src="/assets/loader1.gif" alt="Loading..." />
  </div>
  
   <div class="container-fluid">
      <table class="table table-hover table-bordered display">
        <thead>
          <tr>
                <th>#</th>
                <th>Bill No</th>
                <th>Date</th>
                <th>Head</th>
                <th>Particular</th>
                <th>Amount</th>
                <th>Remarks</th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
          </thead>
          <tbody>
          <tr *ngFor="let bl of bill; let i = index">
            <td scope="row">{{i+1}}</td>
            <td>{{bl.billNo}}</td>
              <td>{{bl.dated|date:'MMM dd, yyyy'}}</td>
              <td>{{bl.head|uppercase}}</td>
              <td>
                <span *ngFor="let bd of bl.BillDetails">{{bd.particular|uppercase}}<br/> </span>
              </td>
              <td align='right' width="105px">
              <!--  {{bl.amount|number:'1.2'}}-->
              <span *ngFor="let bd of bl.BillDetails">{{bd.amount}}<br/> </span>
              -------------------<br/>
             <span style="font-weight:bold">$ {{getSum(bl.BillDetails)|number:'1.2'}}</span>
              </td>
              <td>{{bl.remarks}}</td>
              
              <td><a (click)="copy(bl)" title="Copy"><span class="glyphicon glyphicon-copy"></span></a></td>
              <td><a (click)="edit(bl)" title="Edit"><span class="glyphicon glyphicon-edit"></span></a></td>
              <td><a (click)="delete(bl.id)" title="Delete"><span class="glyphicon glyphicon-trash"></span></a></td>
              <td><a href='#'  routerLink='/invoice/{{bl.id}}' title='Print'><span class='glyphicon glyphicon-print'></span></a></td>
              
            </tr>
        </tbody>
      </table> 
      
       
  </div>
