<div class="container">
    <form [formGroup]="commissionForm" novalidate (ngSubmit)="onSubmit()">
        <h2>Update Commision for Invoice No. {{selectedInvoiceNo}}</h2>
        <div formArrayName="TicketDetails" *ngFor="let ticketDetail of tickets.TicketDetails; let i=index">
            <div [formGroupName]="i">

                <div class="row">
                    <div class="col-md-3 form-group">
                        <label class="control-label" for="commission">Passenger Name</label>
                        <input class="form-control" id="name" name="name" value="{{ticketDetail.passengerName}}"
                            placeholder="" readonly />
                    </div>
                    <div class="col-md-1 form-group">
                        <label class="control-label" for="commission">Commission</label>
                        <input class="form-control" id="commission" name="commission"
                            value="{{ticketDetail.commission}}" placeholder="Comm" formControlName="commission" />
                    </div>
                </div>

            </div>
        </div>
        <div>
            {{message}}
        </div>
        <button type="submit"  [disabled]="!isButton" class="btn btn-info col-sm-2" name="submit" id="submit" value="save">Update</button>
        
    </form>
</div>
<!-- {{commissionForm.value|json}} -->