import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Advertise } from './advertise.service';
@Component({
  selector: 'app-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.css']
})
export class AdvertiseComponent implements OnInit {

  @ViewChild('UploadFileInput', { static: false }) uploadFileInput?: ElementRef;
  fileName: string = '';
  fileInputLabel?: string;
  fileUploadedMessage: string = '';
  selectedId: number = -1;
  advertisement: any;
  picURL = environment.picURL;
  fileUploadForm: FormGroup;
  isLoader: boolean = false;
  readonly baseURL = environment.baseURL;
  readonly advertiseImagePath = environment.advertiseImagePath;
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private advertiseService: Advertise
  ) {
    this.fileUploadForm = this.formBuilder.group({
      uploadedImage: [''],
      id: ['']
    });
  }

  ngOnInit(): void {
    //this.selectedId = this.route.snapshot.params['id'];
    this.refreshList();
  }

  refreshList() {

    // Call your service method here to get data
  }

  onFileSelect(event: any) {
    // console.log('file select',event);
    this.fileUploadedMessage = "";
    const file = event.target.files[0];
    this.fileInputLabel = file.name;
    this.fileUploadForm.get('uploadedImage')?.setValue(file);
  }

  onFormSubmit() {

    if (!this.fileUploadForm.get('uploadedImage')?.value) {
      alert('Please select a file to upload!');
      return;
    }
    this.isLoader = true;
    this.selectedId = 1;
    const formData = new FormData();
    if (this.fileUploadForm.controls['uploadedImage'].value) {
      this.fileName = this.fileUploadForm.controls['uploadedImage'].value.name;
      formData.append('uploadedImage', this.fileUploadForm.controls['uploadedImage'].value);
      formData.append('id', this.selectedId.toString());
    }

    this.http.post<any>(this.baseURL + '/upload', formData).subscribe(response => {
      if (response.statusCode === 200) {
        if (this.uploadFileInput) {
          this.uploadFileInput.nativeElement.value = "";
          this.fileInputLabel = undefined;
          this.fileUploadedMessage = response.status;
          this.refreshList();
          this.sendMail();
          this.gotoTop();
        }
      }
    }, error => {
      console.log("Error:", error);
      alert(error.statusText);
      this.isLoader = false;
    });
  }


  sendMail() {
    this.advertiseService.getCustomerEmails().subscribe((res: any) => {
      // console.log(this.fileName + ' emails : ', res[0].emails);
      const mailBody = {
        "name": "Jagjit Singh",
        "phone": "7785513090",
        "email": "info@jrtravel.ca",
        "msg": "JR Travel thanks for your kind consideration",
        "url": "<img src='" + this.advertiseImagePath + this.fileName + "'  alt='image'/>",
        "mailTo": 'jrtaxtravel@gmail.com ',
        "bccTo": res[0].emails,
        /*"bccTo": "dba.sukhdev@gmail.com",*/
      }
      // console.log(mailBody);
      this.advertiseService.postMail(mailBody).subscribe((res: any) => {
        console.log("mail posted : ", res);
        this.isLoader = false;
      }, error => {
        this.isLoader = false;
        alert(error.statusText);

      });
    }, error => {
      this.isLoader = false;
      alert(error.statusText);
    });
  }
  gotoTop() {
    window.scroll({
      top: 80,
      left: 0,
      behavior: 'smooth'
    });
  }
}