<nav class="navbar navbar-inverse">
    <div class="container-fluid">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>                        
        </button>
        <a class="navbar-brand" href="#"><b><img src="{{logoURL}}" width="25px"></b></a>
      </div>
      <div class="collapse navbar-collapse" id="myNavbar">
        <ul class="nav navbar-nav">
          <li><a routerLink="/bill" href="#">Bill</a></li>
          <!--<li><a routerLink="/create-ticket/0/0/0" href="#">New Ticket</a></li>
          <li><a routerLink="/customer" href="#">Add Customer</a></li>-->
          <li><a routerLink="/customer-search" href="#">Customer</a></li>
          <li><a routerLink="/vendor" href="#">Vendor</a></li>
          <li><a routerLink="/ticket-report" href="#">Ticket Report</a></li>
          <!-- <li><a routerLink="/parent" href="#">Add Parent</a></li> -->
          <li><a routerLink="/parent-search" href="#"><span class="glyphicon glyphicon-user"></span> Day Care</a></li>
          <li><a routerLink="/day-care-bill" href="#"><span class="glyphicon glyphicon-user"></span>Day Care Bill</a></li>
          <li><a routerLink="/day-book" href="#">Day Book</a></li>
          <li><a routerLink="/advertise" href="#">Advertisement</a></li>
        </ul>
        <!-- <ul class="nav navbar-nav navbar-right">
          <li><a href="#"><span class="glyphicon glyphicon-user"></span> Sign Up</a></li>
          <li><a href="#"><span class="glyphicon glyphicon-log-out"></span> Log out</a></li>
        </ul> -->
      </div>
    </div>
  </nav>