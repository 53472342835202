import { Component, OnInit } from '@angular/core';
//import { AuthService } from '../_services/auth.service';
import { AuthenticationService } from '../_services';
import { TokenStorageService } from '../_services/token-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  userName: string[] = [];
  returnUrl: string = '/'; // Default to home
  constructor(
    private authService: AuthenticationService, 
    private tokenStorage: TokenStorageService,
    private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.userName = this.tokenStorage.getUser().username;
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  onSubmit(): void {
    const { username, password } = this.form;

    this.authService.login(username, password).subscribe({
      next: data => {
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.userName = this.tokenStorage.getUser().username;
       this.reloadPage();
        this.router.navigate([this.returnUrl]);
      },
      error: err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    });
  }

  reloadPage(): void {
    window.location.reload();
  }
}

