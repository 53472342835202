<div class="container">
    <div *ngIf="tickets">
        <div *ngFor="let ticket of tickets.ticket; let i = index">
            <p><span class="p1">Invoice No. :</span><span class="p2">{{ticket.invoiceNo}}</span> </p><br/>
            <p><span class="p1">Date :</span> <span class="p2">{{ticket.dated|date:'MMM dd, yyyy'}}</span></p><br/>
            <p><span class="p1">Name : </span><span class="p2">{{ticket.customerName}}</span></p><br/>
            <p><span class="p1">Amount :</span> <span class="p2">{{ticket.totalAmount}}</span></p><br/>
            <p><span class="p1">Vendor :</span><span class="p2"> {{ticket.vendorName}}</span></p><br/>
        </div>
        <p class="p1"><u>Passenger Detail:</u></p>
        <table width="500px" class="table table-hover" >
            <thead>
                <th>#</th>
                <th>Passenger Name</th>
                <th>Age Group</th>
                <th>Amount</th>
                <th>Commission</th>
            </thead>
            <tbody>
            <tr *ngFor="let ticketDetail of tickets.ticketDetail;let i=index">
                <td>{{i+1}}</td>
            <td>{{ticketDetail.passengerName}}</td>
            <td>{{ticketDetail.passengerType}}</td>
            <td>{{ticketDetail.salePrice}}</td>
           <td>{{ticketDetail.commission}}</td>
           </tr>
           </tbody>
           </table>
        </div>
    </div>
   