import { Component, OnInit,TemplateRef, ViewChild  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TicketService } from '../ticket/ticket.service';
import { CustomerService } from './customer.service';
import { MatDialog } from '@angular/material/dialog';
import { AddTicketCommissionComponent } from '../ticket/add-ticket-commission.component';
import { Ticket } from 'src/app/model/entity/ticket';
@Component({
  selector: 'app-customer-tickets',
  templateUrl: './customer-tickets.component.html',
  styleUrls: ['./customer-tickets.component.css']
})
export class CustomerTicketsComponent implements OnInit {
  @ViewChild('dialogRef')
  dialogRef!: TemplateRef<any>;
  selectedId: number = -1;
  selectedCustomer: string = '';
  tickets: any;//for all tickets of the selected customer
  ticket: any;//
  isTicketDetail: boolean = false;
  constructor(private route: ActivatedRoute, private customerService: CustomerService, private ticketService: TicketService,public dialog: MatDialog) { }
  ngOnInit(): void {
    this.selectedId = this.route.snapshot.params['id'];
    this.selectedCustomer = this.route.snapshot.params['name'];
    this.customerService.getCustomerTickets(this.selectedId).subscribe((res => {
      this.tickets = res;

    }))
  }
  showTicketDetail(_id: number) {
    this.ticketService.getTicket(_id).subscribe((res => {
      this.ticket = res;
      this.isTicketDetail = this.ticket.TicketDetails.length > 0 ? true : false;
      this.gotoTop();
    }))
  }
  editTicket(_id: number) {
    console.log(_id);
    this.ticketService.getTicket(_id).subscribe((res => {
    const myBasicDialog = this.dialog.open(AddTicketCommissionComponent, { 
      data: res ,
      height:'100%',
      width:'60%',
      autoFocus: false,
     // disableClose: true
     panelClass: ['w-11/12', 'md:w-7/12', 'xl:w-5/12', '2xl:w-3/12'],
     position: {
      top: '50vh',
      right: '.2vw'
    },
    });

    //console.log(myCompDialog);
    myBasicDialog.afterClosed().subscribe((res) => {
      // Data back from dialog
      //console.log('res',{ res });
      
    });
    myBasicDialog.afterOpened().subscribe((res) => {
      // Data back from dialog
      //console.log('res',{ res });
      scroll:top;
      
    });
  }));
  }

  gotoTop() {
    window.scroll({
      top: 120,
      left: 10,
      behavior: 'smooth'
    });
  }
}

