import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';

import { FormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AuthInterceptsService } from './_services/auth.intercepts.service';
import {ReactiveFormsModule} from '@angular/forms'
import { BillComponent } from './form/bill/bill.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { InvoiceComponent } from './print/invoice.component';
//for qr code
//import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { QRCodeModule } from 'angularx-qrcode';
import { RemoveCommaPipe } from './remove-comma.pipe';
import { TicketComponent } from './form/ticket/ticket.component';
import { CustomerComponent } from './form/customer/customer.component';
import { VendorComponent } from './form/vendor/vendor.component';
import { ShowTicketComponent } from './form/ticket/show-ticket.component';
import { CustomerSearchComponent } from './form/customer/customer-search.component';
import { CustomerTicketsComponent } from './form/customer/customer-tickets.component';
import { AddTicketCommissionComponent } from './form/ticket/add-ticket-commission.component';
import { TicketInvoiceComponent } from './print/ticket-invoice.component';
import { TicketReportComponent } from './form/ticket/ticket-report.component';
import { MenuComponent } from './menu/menu.component';
import { environment } from 'src/environments/environment';
import { DaycareComponent } from './form/daycare/daycare.component';

import { ParentComponent } from './form/daycare/parent.component';
import { ChildComponent } from './form/daycare/child.component';
import { FeeComponent } from './form/daycare/fee.component';
import { FeeInvoiceComponent } from './print/fee-invoice.component';
import { SearchComponent } from './form/daycare/search.component';
import { DaybookComponent } from './form/daybook/day-book.component';
import { DayCareBillComponent } from './form/daycare-bill/bill.component';//added on 20-12-2023
import { DayCareFeeInvoiceComponent } from './print/daycare-fee-invoice.component';
import { DayCareBillInvoiceComponent } from './form/daycare-bill/invoice.component';
import { AdvertiseComponent } from './form/advertise/advertise.component';
import { LoginComponent } from './login/login.component';
//import { AdvertisementComponent } from './form/advertisement/advertisement.component';
//import { DataTablesModule } from 'angular-datatables';

@NgModule({
  declarations: [
    AppComponent,
    BillComponent,
    InvoiceComponent,
    RemoveCommaPipe,
    TicketComponent,
    CustomerComponent,
    VendorComponent,
    ShowTicketComponent,
    CustomerSearchComponent,
    CustomerTicketsComponent,
    AddTicketCommissionComponent,
    TicketInvoiceComponent,
    TicketReportComponent,
    MenuComponent,
    DaycareComponent,
    ParentComponent,
    ChildComponent,
    FeeComponent,
    FeeInvoiceComponent,
    SearchComponent,
    DaybookComponent,
    DayCareBillComponent,
    DayCareFeeInvoiceComponent,
    DayCareBillInvoiceComponent,
    AdvertiseComponent,
    LoginComponent
   // AdvertisementComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
   //NoopAnimationsModule,
    QRCodeModule,
   //DataTablesModule
   MatDialogModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptsService,
        multi: true // Allows multiple interceptors if needed
      },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  loaderURL:string=environment.baseURL;
  logoURL:string=environment.logoURL;
  
 }
